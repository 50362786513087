<section>
  <div class="header d-flex gap-2 align-items-center justify-content-between">
    <div class="d-flex gap-2 align-items-center">
      <img
        src="assets/images/pharmacy 1@2x.png"
        height="24"
        width="24"
        alt=""
      />
      <p>Select Clinic</p>
    </div>
    <div>
      <svg-icon
        src="assets/images/svg/cross.svg"
        class="cursor-pointer"
        (click)="bottomSheet.dismiss(false)"
      ></svg-icon>
    </div>
  </div>
  <div class="doctor-detail d-flex gap-2">
    <div class="profile-pic" *ngIf="doctorData?.profilePic">
      <img loading="lazy" [src]="doctorData?.profilePic" alt="" />
    </div>
    <div class="profile-pic" *ngIf="!doctorData?.profilePic">
      <div
        class="d-flex justify-content-center align-items-center text-18 h-100"
      >
        {{ doctorData?.fullName | nameInitial | async | uppercase }}
      </div>
    </div>
    <div>
      <p class="m-0 text-16 font-600">{{ doctorData?.fullName }}</p>
      <span class="text-16 font-400 opacity-80">{{
        (doctorData?.specialization | formatarray : "name") || "N/A"
      }}</span>
      &nbsp;&nbsp;
      <span class="text-16 font-600"
        >{{ doctorData?.experience || 0 }} Years Experience</span
      >
      <div class="d-flex">
        <div class="recomm d-flex align-items-center">
          <img
            src="assets/images/homepage/thumbs-up.svg"
            alt=""
            height="20"
            width="20"
            class="m-0"
          />
          {{ (doctorData?.recommended | number : ".0-2") || "0" }}% &nbsp;&nbsp;
        </div>
        <img
          src="assets/images/svg/stars.svg"
          alt=""
          width="20"
          height="20"
          class="m-0"
        />&nbsp;
        <span>{{ doctorData?.rating || 0 }}</span>
      </div>
    </div>
  </div>
  <div class="hospital-wrapper">
    <ng-container *ngFor="let hospital of doctorData?.establishmentmaster">
      <div
      *ngIf="hospital?.isDeleted==false && hospital?.isActive==true"
        class="hospital d-flex align-items-center gap-2 justify-content-between"
      >
        <div class="data">
          <div class="add text-16 font-600" *ngIf="hospital?.consultationFees!=-1">
            {{ hospital?.address?.locality ?? hospital?.address?.landmark }} ,
            {{ hospital?.address?.city }}
          </div>
          <div class="name text-16 font-600 mt-2">
            {{ hospital?.name }}
          </div>
          <div class="text-16 font-600 mt-2">
            ₹{{ hospital?.consultationFees==-1 ? hospital?.videoConsultationFees : hospital?.consultationFees  }}
            <span class="font-400 opacity-80">Consultant Fees</span>
          </div>
        </div>
        <div class="select">
          <button class="text-16 font-600" (click)="selectHospital(hospital)">
            Select
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</section>
