import * as i0 from '@angular/core';
import { Injectable, Directive, Input, Component, Optional, HostBinding, NgModule } from '@angular/core';
import { parse, icon, counter, text } from '@fortawesome/fontawesome-svg-core';
import * as i2 from '@angular/platform-browser';
const _c0 = ["*"];
const faWarnIfIconDefinitionMissing = iconSpec => {
  throw new Error(`Could not find icon with iconName=${iconSpec.iconName} and prefix=${iconSpec.prefix} in the icon library.`);
};
const faWarnIfIconSpecMissing = () => {
  throw new Error('Property `icon` is required for `fa-icon`/`fa-duotone-icon` components.');
};

/**
 * Fontawesome class list.
 * Returns classes array by props.
 */
const faClassList = props => {
  const classes = {
    [`fa-${props.animation}`]: props.animation != null && !props.animation.startsWith('spin'),
    'fa-spin': props.animation === 'spin' || props.animation === 'spin-reverse',
    'fa-spin-pulse': props.animation === 'spin-pulse' || props.animation === 'spin-pulse-reverse',
    'fa-spin-reverse': props.animation === 'spin-reverse' || props.animation === 'spin-pulse-reverse',
    // According to https://fontawesome.com/docs/web/style/animate#spin fa-pulse
    // class is deprecated, remove the below line when Font Awesome 5 support
    // is dropped.
    'fa-pulse': props.animation === 'spin-pulse' || props.animation === 'spin-pulse-reverse',
    'fa-fw': props.fixedWidth,
    'fa-border': props.border,
    'fa-inverse': props.inverse,
    'fa-layers-counter': props.counter,
    'fa-flip-horizontal': props.flip === 'horizontal' || props.flip === 'both',
    'fa-flip-vertical': props.flip === 'vertical' || props.flip === 'both',
    [`fa-${props.size}`]: props.size !== null,
    [`fa-rotate-${props.rotate}`]: props.rotate !== null,
    [`fa-pull-${props.pull}`]: props.pull !== null,
    [`fa-stack-${props.stackItemSize}`]: props.stackItemSize != null
  };
  return Object.keys(classes).map(key => classes[key] ? key : null).filter(key => key);
};

/**
 * Returns if is IconLookup or not.
 */
const isIconLookup = i => i.prefix !== undefined && i.iconName !== undefined;

/**
 * Normalizing icon spec.
 */
const faNormalizeIconSpec = (iconSpec, defaultPrefix) => {
  if (isIconLookup(iconSpec)) {
    return iconSpec;
  }
  if (typeof iconSpec === 'string') {
    return {
      prefix: defaultPrefix,
      iconName: iconSpec
    };
  }
  return {
    prefix: iconSpec[0],
    iconName: iconSpec[1]
  };
};
class FaConfig {
  constructor() {
    /**
     * Default prefix to use, when one is not provided with the icon name.
     *
     * @default 'fas'
     */
    this.defaultPrefix = 'fas';
    /**
     * Provides a fallback icon to use whilst main icon is being loaded asynchronously.
     * When value is null, then fa-icon component will throw an error if icon input is missing.
     * When value is not null, then the provided icon will be used as a fallback icon if icon input is missing.
     *
     * @default null
     */
    this.fallbackIcon = null;
  }
  static {
    this.ɵfac = function FaConfig_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaConfig)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FaConfig,
      factory: FaConfig.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class FaIconLibrary {
  constructor() {
    this.definitions = {};
  }
  addIcons(...icons) {
    for (const icon of icons) {
      if (!(icon.prefix in this.definitions)) {
        this.definitions[icon.prefix] = {};
      }
      this.definitions[icon.prefix][icon.iconName] = icon;
      for (const alias of icon.icon[2]) {
        if (typeof alias === 'string') {
          this.definitions[icon.prefix][alias] = icon;
        }
      }
    }
  }
  addIconPacks(...packs) {
    for (const pack of packs) {
      const icons = Object.keys(pack).map(key => pack[key]);
      this.addIcons(...icons);
    }
  }
  getIconDefinition(prefix, name) {
    if (prefix in this.definitions && name in this.definitions[prefix]) {
      return this.definitions[prefix][name];
    }
    return null;
  }
  static {
    this.ɵfac = function FaIconLibrary_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaIconLibrary)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: FaIconLibrary,
      factory: FaIconLibrary.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaIconLibrary, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class FaStackItemSizeDirective {
  constructor() {
    /**
     * Specify whether icon inside {@link FaStackComponent} should be rendered in
     * regular size (1x) or as a larger icon (2x).
     */
    this.stackItemSize = '1x';
  }
  ngOnChanges(changes) {
    if ('size' in changes) {
      throw new Error('fa-icon is not allowed to customize size when used inside fa-stack. ' + 'Set size on the enclosing fa-stack instead: <fa-stack size="4x">...</fa-stack>.');
    }
  }
  static {
    this.ɵfac = function FaStackItemSizeDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaStackItemSizeDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FaStackItemSizeDirective,
      selectors: [["fa-icon", "stackItemSize", ""], ["fa-duotone-icon", "stackItemSize", ""]],
      inputs: {
        stackItemSize: "stackItemSize",
        size: "size"
      },
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaStackItemSizeDirective, [{
    type: Directive,
    args: [{
      // eslint-disable-next-line @angular-eslint/directive-selector
      selector: 'fa-icon[stackItemSize],fa-duotone-icon[stackItemSize]'
    }]
  }], null, {
    stackItemSize: [{
      type: Input
    }],
    size: [{
      type: Input
    }]
  });
})();
class FaStackComponent {
  constructor(renderer, elementRef) {
    this.renderer = renderer;
    this.elementRef = elementRef;
  }
  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'fa-stack');
  }
  ngOnChanges(changes) {
    if ('size' in changes) {
      if (changes.size.currentValue != null) {
        this.renderer.addClass(this.elementRef.nativeElement, `fa-${changes.size.currentValue}`);
      }
      if (changes.size.previousValue != null) {
        this.renderer.removeClass(this.elementRef.nativeElement, `fa-${changes.size.previousValue}`);
      }
    }
  }
  static {
    this.ɵfac = function FaStackComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaStackComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FaStackComponent,
      selectors: [["fa-stack"]],
      inputs: {
        size: "size"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function FaStackComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaStackComponent, [{
    type: Component,
    args: [{
      selector: 'fa-stack',
      template: `<ng-content></ng-content>`
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    size: [{
      type: Input
    }]
  });
})();
class FaIconComponent {
  /**
   * @deprecated Use animation="spin" instead. To be removed in 0.14.0.
   */
  set spin(value) {
    this.animation = value ? 'spin' : undefined;
  }
  /**
   * @deprecated Use animation="spin-pulse" instead. To be removed in 0.14.0.
   */
  set pulse(value) {
    this.animation = value ? 'spin-pulse' : undefined;
  }
  constructor(sanitizer, config, iconLibrary, stackItem, stack) {
    this.sanitizer = sanitizer;
    this.config = config;
    this.iconLibrary = iconLibrary;
    this.stackItem = stackItem;
    /**
     * Set `class` attribute on the SVG element rendered by the component.
     *
     * @deprecated This input breaks view encapsulation and is not recommended.
     * For simple cases (like colors), use `class` on the component itself, for
     * more complex usages, explicitly opt-in to break the view encapsulation.
     * This input is deprecated since 0.12.0 and will be removed in 0.13.0.
     */
    this.classes = [];
    if (stack != null && stackItem == null) {
      console.error('FontAwesome: fa-icon and fa-duotone-icon elements must specify stackItemSize attribute when wrapped into ' + 'fa-stack. Example: <fa-icon stackItemSize="2x"></fa-icon>.');
    }
  }
  ngOnChanges(changes) {
    if (this.icon == null && this.config.fallbackIcon == null) {
      faWarnIfIconSpecMissing();
      return;
    }
    if (changes) {
      const iconToBeRendered = this.icon != null ? this.icon : this.config.fallbackIcon;
      const iconDefinition = this.findIconDefinition(iconToBeRendered);
      if (iconDefinition != null) {
        const params = this.buildParams();
        this.renderIcon(iconDefinition, params);
      }
    }
  }
  /**
   * Programmatically trigger rendering of the icon.
   *
   * This method is useful, when creating {@link FaIconComponent} dynamically or
   * changing its inputs programmatically as in these cases icon won't be
   * re-rendered automatically.
   */
  render() {
    this.ngOnChanges({});
  }
  findIconDefinition(i) {
    const lookup = faNormalizeIconSpec(i, this.config.defaultPrefix);
    if ('icon' in lookup) {
      return lookup;
    }
    const definition = this.iconLibrary.getIconDefinition(lookup.prefix, lookup.iconName);
    if (definition != null) {
      return definition;
    }
    faWarnIfIconDefinitionMissing(lookup);
    return null;
  }
  buildParams() {
    const classOpts = {
      flip: this.flip,
      animation: this.animation,
      border: this.border,
      inverse: this.inverse,
      size: this.size || null,
      pull: this.pull || null,
      rotate: this.rotate || null,
      fixedWidth: typeof this.fixedWidth === 'boolean' ? this.fixedWidth : this.config.fixedWidth,
      stackItemSize: this.stackItem != null ? this.stackItem.stackItemSize : null
    };
    const parsedTransform = typeof this.transform === 'string' ? parse.transform(this.transform) : this.transform;
    return {
      title: this.title,
      transform: parsedTransform,
      classes: [...faClassList(classOpts), ...this.classes],
      mask: this.mask != null ? this.findIconDefinition(this.mask) : null,
      styles: this.styles != null ? this.styles : {},
      symbol: this.symbol,
      attributes: {
        role: this.a11yRole
      }
    };
  }
  renderIcon(definition, params) {
    const renderedIcon = icon(definition, params);
    this.renderedIconHTML = this.sanitizer.bypassSecurityTrustHtml(renderedIcon.html.join('\n'));
  }
  static {
    this.ɵfac = function FaIconComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaIconComponent)(i0.ɵɵdirectiveInject(i2.DomSanitizer), i0.ɵɵdirectiveInject(FaConfig), i0.ɵɵdirectiveInject(FaIconLibrary), i0.ɵɵdirectiveInject(FaStackItemSizeDirective, 8), i0.ɵɵdirectiveInject(FaStackComponent, 8));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FaIconComponent,
      selectors: [["fa-icon"]],
      hostAttrs: [1, "ng-fa-icon"],
      hostVars: 2,
      hostBindings: function FaIconComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("innerHTML", ctx.renderedIconHTML, i0.ɵɵsanitizeHtml);
          i0.ɵɵattribute("title", ctx.title);
        }
      },
      inputs: {
        icon: "icon",
        title: "title",
        animation: "animation",
        spin: "spin",
        pulse: "pulse",
        mask: "mask",
        styles: "styles",
        flip: "flip",
        size: "size",
        pull: "pull",
        border: "border",
        inverse: "inverse",
        symbol: "symbol",
        rotate: "rotate",
        fixedWidth: "fixedWidth",
        classes: "classes",
        transform: "transform",
        a11yRole: "a11yRole"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function FaIconComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaIconComponent, [{
    type: Component,
    args: [{
      selector: 'fa-icon',
      template: ``,
      host: {
        class: 'ng-fa-icon',
        '[attr.title]': 'title'
      }
    }]
  }], function () {
    return [{
      type: i2.DomSanitizer
    }, {
      type: FaConfig
    }, {
      type: FaIconLibrary
    }, {
      type: FaStackItemSizeDirective,
      decorators: [{
        type: Optional
      }]
    }, {
      type: FaStackComponent,
      decorators: [{
        type: Optional
      }]
    }];
  }, {
    icon: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    animation: [{
      type: Input
    }],
    spin: [{
      type: Input
    }],
    pulse: [{
      type: Input
    }],
    mask: [{
      type: Input
    }],
    styles: [{
      type: Input
    }],
    flip: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    pull: [{
      type: Input
    }],
    border: [{
      type: Input
    }],
    inverse: [{
      type: Input
    }],
    symbol: [{
      type: Input
    }],
    rotate: [{
      type: Input
    }],
    fixedWidth: [{
      type: Input
    }],
    classes: [{
      type: Input
    }],
    transform: [{
      type: Input
    }],
    a11yRole: [{
      type: Input
    }],
    renderedIconHTML: [{
      type: HostBinding,
      args: ['innerHTML']
    }]
  });
})();
class FaDuotoneIconComponent extends FaIconComponent {
  findIconDefinition(i) {
    const definition = super.findIconDefinition(i);
    if (definition != null && !Array.isArray(definition.icon[4])) {
      throw new Error('The specified icon does not appear to be a Duotone icon. ' + 'Check that you specified the correct style: ' + `<fa-duotone-icon [icon]="['fad', '${definition.iconName}']"></fa-duotone-icon> ` + `or use: <fa-icon icon="${definition.iconName}"></fa-icon> instead.`);
    }
    return definition;
  }
  buildParams() {
    const params = super.buildParams();
    if (this.swapOpacity === true || this.swapOpacity === 'true') {
      params.classes.push('fa-swap-opacity');
    }
    if (this.primaryOpacity != null) {
      params.styles['--fa-primary-opacity'] = this.primaryOpacity.toString();
    }
    if (this.secondaryOpacity != null) {
      params.styles['--fa-secondary-opacity'] = this.secondaryOpacity.toString();
    }
    if (this.primaryColor != null) {
      params.styles['--fa-primary-color'] = this.primaryColor;
    }
    if (this.secondaryColor != null) {
      params.styles['--fa-secondary-color'] = this.secondaryColor;
    }
    return params;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵFaDuotoneIconComponent_BaseFactory;
      return function FaDuotoneIconComponent_Factory(__ngFactoryType__) {
        return (ɵFaDuotoneIconComponent_BaseFactory || (ɵFaDuotoneIconComponent_BaseFactory = i0.ɵɵgetInheritedFactory(FaDuotoneIconComponent)))(__ngFactoryType__ || FaDuotoneIconComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FaDuotoneIconComponent,
      selectors: [["fa-duotone-icon"]],
      inputs: {
        swapOpacity: "swapOpacity",
        primaryOpacity: "primaryOpacity",
        secondaryOpacity: "secondaryOpacity",
        primaryColor: "primaryColor",
        secondaryColor: "secondaryColor"
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 0,
      vars: 0,
      template: function FaDuotoneIconComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaDuotoneIconComponent, [{
    type: Component,
    args: [{
      selector: 'fa-duotone-icon',
      template: ``
    }]
  }], null, {
    swapOpacity: [{
      type: Input
    }],
    primaryOpacity: [{
      type: Input
    }],
    secondaryOpacity: [{
      type: Input
    }],
    primaryColor: [{
      type: Input
    }],
    secondaryColor: [{
      type: Input
    }]
  });
})();

/**
 * Warns if parent component not existing.
 */
const faWarnIfParentNotExist = (parent, parentName, childName) => {
  if (!parent) {
    throw new Error(`${childName} should be used as child of ${parentName} only.`);
  }
};

/**
 * Fontawesome layers.
 */
class FaLayersComponent {
  constructor(renderer, elementRef, config) {
    this.renderer = renderer;
    this.elementRef = elementRef;
    this.config = config;
  }
  ngOnInit() {
    this.renderer.addClass(this.elementRef.nativeElement, 'fa-layers');
    this.fixedWidth = typeof this.fixedWidth === 'boolean' ? this.fixedWidth : this.config.fixedWidth;
  }
  ngOnChanges(changes) {
    if ('size' in changes) {
      if (changes.size.currentValue != null) {
        this.renderer.addClass(this.elementRef.nativeElement, `fa-${changes.size.currentValue}`);
      }
      if (changes.size.previousValue != null) {
        this.renderer.removeClass(this.elementRef.nativeElement, `fa-${changes.size.previousValue}`);
      }
    }
  }
  static {
    this.ɵfac = function FaLayersComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaLayersComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(FaConfig));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FaLayersComponent,
      selectors: [["fa-layers"]],
      hostVars: 2,
      hostBindings: function FaLayersComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("fa-fw", ctx.fixedWidth);
        }
      },
      inputs: {
        size: "size",
        fixedWidth: "fixedWidth"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function FaLayersComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaLayersComponent, [{
    type: Component,
    args: [{
      selector: 'fa-layers',
      template: `<ng-content></ng-content>`
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }, {
      type: FaConfig
    }];
  }, {
    size: [{
      type: Input
    }],
    fixedWidth: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['class.fa-fw']
    }]
  });
})();
class FaLayersCounterComponent {
  constructor(parent, sanitizer) {
    this.parent = parent;
    this.sanitizer = sanitizer;
    /**
     * Set `class` attribute on the SVG element rendered by the component.
     *
     * @deprecated This input breaks view encapsulation and is not recommended.
     * For simple cases (like colors), use `class` on the component itself, for
     * more complex usages, explicitly opt-in to break the view encapsulation.
     * This input is deprecated since 0.12.0 and will be removed in 0.13.0.
     */
    this.classes = [];
    faWarnIfParentNotExist(this.parent, 'FaLayersComponent', this.constructor.name);
  }
  ngOnChanges(changes) {
    if (changes) {
      const params = this.buildParams();
      this.updateContent(params);
    }
  }
  buildParams() {
    const classes = [];
    if (this.classes != null) {
      classes.push(...this.classes);
    }
    if (this.position != null) {
      classes.push(`fa-layers-${this.position}`);
    }
    return {
      title: this.title,
      classes,
      styles: this.styles
    };
  }
  updateContent(params) {
    this.renderedHTML = this.sanitizer.bypassSecurityTrustHtml(counter(this.content || '', params).html.join(''));
  }
  static {
    this.ɵfac = function FaLayersCounterComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaLayersCounterComponent)(i0.ɵɵdirectiveInject(FaLayersComponent, 8), i0.ɵɵdirectiveInject(i2.DomSanitizer));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FaLayersCounterComponent,
      selectors: [["fa-layers-counter"]],
      hostAttrs: [1, "ng-fa-layers-counter"],
      hostVars: 1,
      hostBindings: function FaLayersCounterComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("innerHTML", ctx.renderedHTML, i0.ɵɵsanitizeHtml);
        }
      },
      inputs: {
        content: "content",
        title: "title",
        styles: "styles",
        classes: "classes",
        position: "position"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function FaLayersCounterComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaLayersCounterComponent, [{
    type: Component,
    args: [{
      selector: 'fa-layers-counter',
      template: '',
      host: {
        class: 'ng-fa-layers-counter'
      }
    }]
  }], function () {
    return [{
      type: FaLayersComponent,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i2.DomSanitizer
    }];
  }, {
    content: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    styles: [{
      type: Input
    }],
    classes: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    renderedHTML: [{
      type: HostBinding,
      args: ['innerHTML']
    }]
  });
})();
class FaLayersTextComponent {
  /**
   * @deprecated This input was incorrectly exposed and never worked correctly. To be removed in 0.14.0.
   */
  set spin(value) {
    this.animation = value ? 'spin' : undefined;
  }
  /**
   * @deprecated This input was incorrectly exposed and never worked correctly. To be removed in 0.14.0.
   */
  set pulse(value) {
    this.animation = value ? 'spin-pulse' : undefined;
  }
  constructor(parent, sanitizer) {
    this.parent = parent;
    this.sanitizer = sanitizer;
    /**
     * Set `class` attribute on the SVG element rendered by the component.
     *
     * @deprecated This input breaks view encapsulation and is not recommended.
     * For simple cases (like colors), use `class` on the component itself, for
     * more complex usages, explicitly opt-in to break the view encapsulation.
     * This input is deprecated since 0.12.0 and will be removed in 0.13.0.
     */
    this.classes = [];
    faWarnIfParentNotExist(this.parent, 'FaLayersComponent', this.constructor.name);
  }
  ngOnChanges(changes) {
    if (changes) {
      const params = this.buildParams();
      this.updateContent(params);
    }
  }
  /**
   * Updating params by component props.
   */
  buildParams() {
    const classOpts = {
      flip: this.flip,
      animation: this.animation,
      border: this.border,
      inverse: this.inverse,
      size: this.size || null,
      pull: this.pull || null,
      rotate: this.rotate || null,
      fixedWidth: this.fixedWidth
    };
    const parsedTransform = typeof this.transform === 'string' ? parse.transform(this.transform) : this.transform;
    return {
      transform: parsedTransform,
      classes: [...faClassList(classOpts), ...this.classes],
      title: this.title,
      styles: this.styles
    };
  }
  updateContent(params) {
    this.renderedHTML = this.sanitizer.bypassSecurityTrustHtml(text(this.content || '', params).html.join('\n'));
  }
  static {
    this.ɵfac = function FaLayersTextComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FaLayersTextComponent)(i0.ɵɵdirectiveInject(FaLayersComponent, 8), i0.ɵɵdirectiveInject(i2.DomSanitizer));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FaLayersTextComponent,
      selectors: [["fa-layers-text"]],
      hostAttrs: [1, "ng-fa-layers-text"],
      hostVars: 1,
      hostBindings: function FaLayersTextComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵhostProperty("innerHTML", ctx.renderedHTML, i0.ɵɵsanitizeHtml);
        }
      },
      inputs: {
        content: "content",
        title: "title",
        styles: "styles",
        classes: "classes",
        spin: "spin",
        pulse: "pulse",
        flip: "flip",
        size: "size",
        pull: "pull",
        border: "border",
        inverse: "inverse",
        rotate: "rotate",
        fixedWidth: "fixedWidth",
        transform: "transform"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 0,
      vars: 0,
      template: function FaLayersTextComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FaLayersTextComponent, [{
    type: Component,
    args: [{
      selector: 'fa-layers-text',
      template: '',
      host: {
        class: 'ng-fa-layers-text'
      }
    }]
  }], function () {
    return [{
      type: FaLayersComponent,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i2.DomSanitizer
    }];
  }, {
    content: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    styles: [{
      type: Input
    }],
    classes: [{
      type: Input
    }],
    spin: [{
      type: Input
    }],
    pulse: [{
      type: Input
    }],
    flip: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    pull: [{
      type: Input
    }],
    border: [{
      type: Input
    }],
    inverse: [{
      type: Input
    }],
    rotate: [{
      type: Input
    }],
    fixedWidth: [{
      type: Input
    }],
    transform: [{
      type: Input
    }],
    renderedHTML: [{
      type: HostBinding,
      args: ['innerHTML']
    }]
  });
})();
class FontAwesomeModule {
  static {
    this.ɵfac = function FontAwesomeModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FontAwesomeModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FontAwesomeModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FontAwesomeModule, [{
    type: NgModule,
    args: [{
      declarations: [FaIconComponent, FaDuotoneIconComponent, FaLayersComponent, FaLayersTextComponent, FaLayersCounterComponent, FaStackComponent, FaStackItemSizeDirective],
      exports: [FaIconComponent, FaDuotoneIconComponent, FaLayersComponent, FaLayersTextComponent, FaLayersCounterComponent, FaStackComponent, FaStackItemSizeDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FaConfig, FaDuotoneIconComponent, FaIconComponent, FaIconLibrary, FaLayersComponent, FaLayersCounterComponent, FaLayersTextComponent, FaStackComponent, FaStackItemSizeDirective, FontAwesomeModule };
