<div>
  <pre
    *ngIf="!showFullText"
    [class.text-dark]="
      type
    ">{{ truncatedText }}<a (click)="toggleReadMore()" *ngIf="text.length > maxLength">  Read more</a></pre>
  <pre
    *ngIf="showFullText"
    [class.text-dark]="
      type
    ">{{ text }}<a (click)="toggleReadMore()" >  Read less</a></pre>
</div>
