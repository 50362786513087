<div class="cards d-flex flex-column">
  <div class="cards__image">
    <div
      class="profile-icon"
      (click)="viewDoctor()"
      *ngIf="!data?.doctorProfilePicture"
    >
      {{ data?.doctorName | nameInitial | async | uppercase }}
    </div>
    <img
      [src]="data?.doctorProfilePicture"
      (click)="viewDoctor()"
      *ngIf="data?.doctorProfilePicture"
      [alt]="data?.doctorName"
    />
  </div>
  <div class="cards__details">
    <h2 class="cursor-pointer line-clamp-1" (click)="viewDoctor()">
      {{ data?.doctorName || "N/A" }}
    </h2>
    <h3 class="line-clamp-1">
      {{ specailization || "N/A" }}
    </h3>
    <p *ngIf="data?.address?.locality && data?.address?.city">
      {{ formatName(data?.address?.locality + "," + data?.address?.city, 60) }}
    </p>
    <p *ngIf="!data?.address?.locality && data?.address?.city">
      {{ data?.address?.city || "N/A" }}
    </p>
    <p *ngIf="data?.address?.locality && !data?.address?.city">
      {{ data?.address?.locality || "N/A" }}
    </p>
    <p *ngIf="!data?.address?.locality && !data?.address?.city">
      {{ data?.address?.locality || "N/A" }}
    </p>
    <div class="ratings">
      <img
        src="assets/images/svg/stars.svg"
        alt=""
        style="position: relative; top: 3px"
      />
      &nbsp;&nbsp;
      <span class="star">
        {{ (data?.rating | number : "1.1-1") || "0.0" }}
      </span>
      <span class="total"> &nbsp;/5 </span>
      <span class="review" *ngIf="data?.totalReview <= 1"
        >({{ data?.totalReview || 0 }} review)</span
      >
      <span class="review" *ngIf="data?.totalReview > 1"
        >({{ data?.totalReview }} reviews)</span
      >
    </div>
    <div class="price-box" *ngIf="data?.consultationFees!=-1 && data?.consultationFees!=0 && data?.consultationFees!=null && data?.consultationFees!=undefined">
      &#8377;{{ data?.consultationFees || 0 }}
      <span class="price">&nbsp;&nbsp; In-Clinic Consultation Fees </span>
    </div>
    <div class="price-box" *ngIf="data?.videoConsultationFees!=-1 && data?.videoConsultationFees!=0 && data?.videoConsultationFees!=null && data?.videoConsultationFees!=undefined " style="margin-bottom: 5px;">
      &#8377;{{ data?.videoConsultationFees || 0 }}
      <span class="price">&nbsp;&nbsp; Video Consultation Fees </span>
    </div>
  </div>
  <div class="cards__button">
    <button class="" (click)="bookAppointment()">Book Appointment</button>
  </div>
  <div class="cards__wait-time" *ngIf="type == 1 && data?.timeTaken">
    <img loading="lazy" src="assets/images/svg/clock.svg" alt="" />
    <!-- <p>{{ data?.timeTaken || 0 }}</p> -->
  </div>
  <div class="cards__top-doctors" *ngIf="type == 0">
    <img loading="lazy" src="assets/images/homepage/thumbs-up.svg" alt="" />
    <p>{{ (data?.recommended | number : ".0-2") || 0 }}%</p>
  </div>
</div>
