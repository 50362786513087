<section
  class="services"
  *ngIf="!(tab == 0 && !data.length)"
  [ngClass]="{ 'border-bottom-style': tab == 0, 'pt-6': tab == 0 }"
>
  <h2 *ngIf="data.length">Services</h2>
  <div class="services__container">
    <div class="list" *ngFor="let item of serviceArray">
      <div class="d-flex gap-1 my-2">
        <div>
          <img loading="lazy" src="assets/images/serviceCheck.svg" alt="" />
          &nbsp;
        </div>

        <div class="data">{{ item.name }}</div>
      </div>
    </div>
    <div class="view-buttons" *ngIf="data.length && data?.length > 10">
      <button *ngIf="!viewMore" (click)="viewMoreServices()">View More</button>
      <button *ngIf="viewMore" (click)="viewMoreServices()">View Less</button>
    </div>
  </div>
  <div *ngIf="!data.length">
    <h2 class="text-danger text-center">No Service Found</h2>
  </div>
</section>
