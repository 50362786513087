import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, EventEmitter, Directive, Input, Output, HostListener, NgModule } from '@angular/core';
import * as i2 from '@angular/cdk/platform';
import { PlatformModule } from '@angular/cdk/platform';
import * as i3 from '@angular/cdk/clipboard';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DOCUMENT } from '@angular/common';
import { Observable, of, BehaviorSubject, Subject, EMPTY } from 'rxjs';
import { tap, delay, take, takeUntil } from 'rxjs/operators';
import * as i1 from '@angular/platform-browser';
const SHARE_BUTTONS_CONFIG = new InjectionToken('shareButtonsConfig');
class IShareButton {}
var SharerMethod;
(function (SharerMethod) {
  SharerMethod["Anchor"] = "anchor";
  SharerMethod["Window"] = "window";
})(SharerMethod || (SharerMethod = {}));

/**
 * Simple object check.
 */
function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}
/**
 * Deep merge two objects.
 */
function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, {
            [key]: {}
          });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, {
          [key]: source[key]
        });
      }
    }
  }
  return mergeDeep(target, ...sources);
}
/** Returns a valid URL or falls back to current URL */
function getValidUrl(url, fallbackUrl) {
  if (url) {
    const r = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (r.test(url)) {
      return url;
    }
    console.warn(`[ShareButtons]: Sharing link '${url}' is invalid!`);
  }
  return fallbackUrl;
}
function printPage() {
  return new Observable(sub => document.defaultView.print());
}
function copyToClipboard({
  params,
  data,
  clipboard,
  updater
}) {
  return of(null).pipe(tap(() => {
    clipboard.copy(params.url);
    // Disable copy button
    updater.next({
      icon: data.successIcon,
      text: data.successText,
      disabled: true
    });
  }), delay(data.delay), tap(() => updater.next({
    icon: data.icon,
    text: data.text,
    disabled: false
  })), take(1));
}

// Create message body that includes the sharing link used for Email, SMS and WhatsApp buttons
const linkInDescription = {
  description: p => {
    return p.description ? `${p.description}\r\n${p.url}` : p.url;
  }
};
const SHARE_BUTTONS = {
  facebook: {
    type: 'facebook',
    text: 'Facebook',
    ariaLabel: 'Share on Facebook',
    icon: ['fab', 'facebook-f'],
    color: '#4267B2',
    share: {
      desktop: 'https://www.facebook.com/sharer/sharer.php?'
    },
    params: {
      url: 'u'
    }
  },
  twitter: {
    type: 'twitter',
    text: 'Twitter',
    ariaLabel: 'Share on Twitter',
    icon: ['fab', 'twitter'],
    color: '#00acee',
    share: {
      desktop: 'https://twitter.com/intent/tweet?'
    },
    params: {
      url: 'url',
      description: 'text',
      tags: 'hashtags',
      via: 'via'
    }
  },
  linkedin: {
    type: 'linkedin',
    text: 'LinkedIn',
    ariaLabel: 'Share on LinkedIn',
    icon: ['fab', 'linkedin-in'],
    color: '#006fa6',
    share: {
      desktop: 'https://www.linkedin.com/shareArticle?'
    },
    params: {
      url: 'url',
      title: 'title',
      description: 'summary'
    }
  },
  pinterest: {
    type: 'pinterest',
    text: 'Pinterest',
    ariaLabel: 'Share on Pinterest',
    icon: ['fab', 'pinterest-p'],
    color: '#BD091D',
    share: {
      desktop: 'https://pinterest.com/pin/create/button/?'
    },
    params: {
      url: 'url',
      description: 'description',
      image: 'media'
    }
  },
  reddit: {
    type: 'reddit',
    text: 'Reddit',
    ariaLabel: 'Share on Reddit',
    icon: ['fab', 'reddit-alien'],
    color: '#FF4006',
    share: {
      desktop: 'https://www.reddit.com/submit?'
    },
    params: {
      url: 'url',
      title: 'title'
    }
  },
  tumblr: {
    type: 'tumblr',
    text: 'Tumblr',
    ariaLabel: 'Share on Tumblr',
    icon: ['fab', 'tumblr'],
    color: '#36465D',
    share: {
      desktop: 'https://tumblr.com/widgets/share/tool?'
    },
    params: {
      url: 'canonicalUrl',
      description: 'caption',
      tags: 'tags'
    }
  },
  mix: {
    type: 'mix',
    text: 'Mix',
    ariaLabel: 'Share on Mix',
    icon: ['fab', 'mix'],
    color: '#eb4924',
    share: {
      desktop: 'https://mix.com/add?'
    },
    params: {
      url: 'url'
    }
  },
  viber: {
    type: 'viber',
    text: 'Viber',
    ariaLabel: 'Share on Viber',
    icon: ['fab', 'viber'],
    color: '#665ca7',
    share: {
      android: 'viber://forward?',
      ios: 'viber://forward?'
    },
    params: {
      description: 'text'
    },
    paramsFunc: linkInDescription
  },
  vk: {
    type: 'vk',
    text: 'VKontakte',
    ariaLabel: 'Share on VKontakte',
    icon: ['fab', 'vk'],
    color: '#4C75A3',
    share: {
      desktop: 'https://vk.com/share.php?'
    },
    params: {
      url: 'url'
    }
  },
  telegram: {
    type: 'telegram',
    text: 'Telegram',
    ariaLabel: 'Share on Telegram',
    icon: ['fab', 'telegram-plane'],
    color: '#0088cc',
    share: {
      desktop: 'https://t.me/share/url?'
    },
    params: {
      url: 'url',
      description: 'text'
    }
  },
  messenger: {
    type: 'messenger',
    text: 'Messenger',
    ariaLabel: 'Share on Messenger',
    icon: ['fab', 'facebook-messenger'],
    color: '#0080FF',
    share: {
      android: 'fb-messenger://share/?',
      ios: 'fb-messenger://share/?'
    },
    params: {
      url: 'link'
    }
  },
  whatsapp: {
    type: 'whatsapp',
    text: 'WhatsApp',
    ariaLabel: 'Share on WhatsApp',
    icon: ['fab', 'whatsapp'],
    color: '#25D366',
    share: {
      desktop: 'https://web.whatsapp.com/send?',
      android: 'whatsapp://send?',
      ios: 'https://api.whatsapp.com/send?'
    },
    params: {
      description: 'text'
    },
    paramsFunc: linkInDescription
  },
  xing: {
    type: 'xing',
    text: 'Xing',
    ariaLabel: 'Share on Xing',
    icon: ['fab', 'xing'],
    color: '#006567',
    share: {
      desktop: 'https://www.xing.com/spi/shares/new?'
    },
    params: {
      url: 'url'
    }
  },
  line: {
    type: 'line',
    text: 'Line',
    ariaLabel: 'Share on Line',
    icon: ['fab', 'line'],
    color: '#00b900',
    share: {
      desktop: 'https://social-plugins.line.me/lineit/share?'
    },
    params: {
      url: 'url'
    }
  },
  sms: {
    type: 'sms',
    text: 'SMS',
    ariaLabel: 'Share link via SMS',
    icon: ['fas', 'sms'],
    color: '#20c16c',
    share: {
      desktop: 'sms:?',
      ios: 'sms:&'
    },
    params: {
      description: 'body'
    },
    paramsFunc: linkInDescription
  },
  email: {
    type: 'email',
    text: 'Email',
    ariaLabel: 'Share link via email',
    icon: ['fas', 'envelope'],
    color: '#FF961C',
    share: {
      desktop: 'mailto:?'
    },
    params: {
      title: 'subject',
      description: 'body'
    },
    paramsFunc: linkInDescription
  },
  print: {
    type: 'print',
    text: 'Print',
    ariaLabel: 'Print page',
    icon: ['fas', 'print'],
    color: '#765AA2',
    func: printPage
  },
  copy: {
    type: 'copy',
    text: 'Copy link',
    ariaLabel: 'Copy link',
    icon: ['fas', 'link'],
    color: '#607D8B',
    data: {
      text: 'Copy link',
      icon: ['fas', 'link'],
      successText: 'Copied',
      successIcon: ['fas', 'check'],
      delay: 2000
    },
    func: copyToClipboard
  }
};
class ShareService {
  constructor(config, _document) {
    this._document = _document;
    /** Global config that applies on all share buttons in the app */
    this.config = {
      sharerMethod: SharerMethod.Anchor,
      sharerTarget: '_blank',
      windowObj: this._document.defaultView,
      windowFuncName: 'open',
      prop: SHARE_BUTTONS,
      theme: 'default',
      include: [],
      exclude: [],
      autoSetMeta: true,
      windowWidth: 800,
      windowHeight: 500,
      moreButtonIcon: 'ellipsis-h',
      lessButtonIcon: 'minus',
      moreButtonAriaLabel: 'Show more share buttons',
      lessButtonAriaLabel: 'Show less share buttons'
    };
    /** Stream that emits when config changes */
    this.config$ = new BehaviorSubject(this.config);
    if (config) {
      this.setConfig(config);
    }
  }
  /**
   * Share buttons properties, used to get the text, color and icon of each button.
   */
  get prop() {
    return this.config.prop;
  }
  get windowSize() {
    return `width=${this.config.windowWidth}, height=${this.config.windowHeight}`;
  }
  setConfig(config) {
    this.config = mergeDeep(this.config, config);
    this.config$.next(this.config);
  }
  addButton(name, props) {
    this.setConfig({
      prop: {
        [name]: props
      }
    });
  }
}
ShareService.ɵfac = function ShareService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ShareService)(i0.ɵɵinject(SHARE_BUTTONS_CONFIG, 8), i0.ɵɵinject(DOCUMENT));
};
ShareService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ShareService,
  factory: ShareService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShareService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [SHARE_BUTTONS_CONFIG]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();
class ShareDirective {
  constructor(_el, _meta, _platform, _clipboard, _share, _cd, _document) {
    this._meta = _meta;
    this._platform = _platform;
    this._clipboard = _clipboard;
    this._share = _share;
    this._cd = _cd;
    this._document = _document;
    /** Stream that emits when button is destroyed */
    this._destroyed = new Subject();
    /** Stream that emit when share button need to be updated */
    this._updater = new Subject();
    /** Set meta tags from document head, useful when SEO is supported */
    this.autoSetMeta = this._share.config.autoSetMeta;
    /** Sharing link */
    this.url = this._share.config.url;
    /** Sets the title parameter */
    this.title = this._share.config.title;
    /** Sets the description parameter */
    this.description = this._share.config.description;
    /** Sets the image parameter for sharing on Pinterest */
    this.image = this._share.config.image;
    /** Sets the tags parameter for sharing on Twitter and Tumblr */
    this.tags = this._share.config.tags;
    /** Stream that emits when share dialog is opened */
    this.opened = new EventEmitter();
    /** Stream that emits when share dialog is closed */
    this.closed = new EventEmitter();
    this._el = _el.nativeElement;
  }
  /**
   * Share the link
   */
  share() {
    // Avoid SSR error
    if (this._platform.isBrowser && this.shareButton) {
      // Prepare sharer url params
      const params = this.autoSetMeta ? this.getParamsFromMetaTags() : this.getParamsFromInputs();
      // Prepare share button click
      const click = this.shareButton.share ? this.open(params) : this.shareButton.func({
        params,
        data: this.shareButton.data,
        clipboard: this._clipboard,
        updater: this._updater
      });
      click.pipe(takeUntil(this._destroyed)).subscribe();
    } else {
      console.warn(`${this.text} button is not compatible on this Platform`);
    }
  }
  ngOnInit() {
    // This stream is mainly used to update the copy button text and icon when it is being clicked
    this._updater.pipe(tap(data => {
      this.icon = data.icon;
      this.text = data.text;
      this._el.style.pointerEvents = data.disabled ? 'none' : 'auto';
      this._cd.markForCheck();
    }), takeUntil(this._destroyed)).subscribe();
  }
  ngOnChanges(changes) {
    // Avoid SSR error
    if (this._platform.isBrowser) {
      // Create share button
      if (this._shareButtonChanged(changes.shareButtonName)) {
        this._createShareButton();
      }
      // Prepare share url
      if (this._urlChanged(changes.url)) {
        this.url = getValidUrl(this.autoSetMeta ? this.url || this._getMetaTagContent('og:url') : this.url, this._document.defaultView.location.href);
      }
    }
  }
  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }
  _createShareButton() {
    const button = this._share.config.prop[this.shareButtonName];
    if (button) {
      // Set share button properties
      this.shareButton = button;
      // Remove previous button class
      this._el.classList.remove(`sb-${this._buttonClass}`);
      // Add new button class
      this._el.classList.add(`sb-${this.shareButtonName}`);
      // Set button css color variable
      this._el.style.setProperty('--button-color', this.shareButton.color);
      // Keep a copy of the class for future replacement
      this._buttonClass = this.shareButtonName;
      this.color = this.shareButton.color;
      this.text = this.shareButton.text;
      this.icon = this.shareButton.icon;
      // Set aria-label attribute
      this._el.setAttribute('aria-label', button.ariaLabel);
    } else {
      console.error(`[ShareButtons]: The share button '${this.shareButtonName}' does not exist!`);
    }
  }
  /**
   * Get meta tag content
   */
  _getMetaTagContent(key) {
    const metaUsingProperty = this._meta.getTag(`property="${key}"`);
    if (metaUsingProperty) {
      return metaUsingProperty.getAttribute('content');
    }
    const metaUsingName = this._meta.getTag(`name="${key}"`);
    if (metaUsingName) {
      return metaUsingName.getAttribute('content');
    }
  }
  _shareButtonChanged(change) {
    return change && (change.firstChange || change.previousValue !== change.currentValue);
  }
  _urlChanged(change) {
    return !this.url || change && change.previousValue !== change.currentValue;
  }
  /**
   * Get share params from meta tags first and fallback to user inputs
   */
  getParamsFromMetaTags() {
    return {
      url: this.url,
      title: this.title || this._getMetaTagContent('og:title'),
      description: this.description || this._getMetaTagContent('og:description'),
      image: this.image || this._getMetaTagContent('og:image'),
      via: this._share.config.twitterAccount,
      tags: this.tags
    };
  }
  /**
   * Get share params from user inputs
   */
  getParamsFromInputs() {
    return {
      url: this.url,
      title: this.title,
      description: this.description,
      image: this.image,
      tags: this.tags,
      via: this._share.config.twitterAccount
    };
  }
  open(params) {
    // Set sharer link based on user's device
    let sharerLink;
    if (this._platform.IOS && this.shareButton.share.ios) {
      sharerLink = this.shareButton.share.ios;
    } else if (this._platform.ANDROID && this.shareButton.share.android) {
      sharerLink = this.shareButton.share.android;
    } else {
      sharerLink = this.shareButton.share.desktop;
    }
    if (sharerLink) {
      // Set sharer link params
      this._finalUrl = sharerLink + this._serializeParams(params);
      // Log the sharer link in debug mode
      if (this._share.config.debug) {
        console.log('[DEBUG SHARE BUTTON]: ', this._finalUrl);
      }
      // Open the share window
      // There are two methods available for opening the share window:
      // 1. Using a real anchor
      // 2. Using window.open
      const sharerMethod = this.shareButton.method || this._share.config.sharerMethod;
      const sharerTarget = this.shareButton.target || this._share.config.sharerTarget;
      switch (sharerMethod) {
        case SharerMethod.Anchor:
          const linkElement = this._document.createElement('a');
          // Make it open in a new tab/window (depends on user's browser configuration)
          linkElement.setAttribute('target', sharerTarget);
          // Prevent security vulnerability https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c
          linkElement.setAttribute('rel', 'noopener noreferrer');
          linkElement.href = this._finalUrl;
          linkElement.click();
          linkElement.remove();
          break;
        case SharerMethod.Window:
          // Open link using Window object
          const openWindow = this._share.config.windowObj[this._share.config.windowFuncName];
          const popUpWindow = openWindow(this._finalUrl, sharerTarget, this._share.windowSize);
          // Prevent security vulnerability https://medium.com/@jitbit/target-blank-the-most-underestimated-vulnerability-ever-96e328301f4c
          this._share.config.windowObj.opener = null;
          // Resolve when share dialog is closed
          if (popUpWindow) {
            return new Observable(sub => {
              const pollTimer = this._document.defaultView.setInterval(() => {
                if (popUpWindow.closed) {
                  this._document.defaultView.clearInterval(pollTimer);
                  // Emit when share windows is closed
                  this.closed.emit(this.shareButtonName);
                  sub.next();
                  sub.complete();
                }
              }, 200);
            });
          }
          break;
      }
      // Emit when share window is opened
      this.opened.emit(this.shareButtonName);
    }
    return EMPTY;
  }
  _serializeParams(params) {
    return Object.entries(this.shareButton.params).map(([key, value]) => {
      // Check if share button param has a map function
      const paramFunc = this.shareButton.paramsFunc ? this.shareButton.paramsFunc[key] : null;
      if (params[key] || paramFunc) {
        const paramValue = paramFunc ? paramFunc(params) : params[key];
        return `${value}=${encodeURIComponent(paramValue)}`;
      }
      return '';
    }).filter(urlParam => urlParam !== '').join('&');
  }
}
ShareDirective.ɵfac = function ShareDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ShareDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i1.Meta), i0.ɵɵdirectiveInject(i2.Platform), i0.ɵɵdirectiveInject(i3.Clipboard), i0.ɵɵdirectiveInject(ShareService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(DOCUMENT));
};
ShareDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ShareDirective,
  selectors: [["", "shareButton", ""]],
  hostBindings: function ShareDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("click", function ShareDirective_click_HostBindingHandler() {
        return ctx.share();
      });
    }
  },
  inputs: {
    shareButtonName: [0, "shareButton", "shareButtonName"],
    autoSetMeta: "autoSetMeta",
    url: "url",
    title: "title",
    description: "description",
    image: "image",
    tags: "tags"
  },
  outputs: {
    opened: "opened",
    closed: "closed"
  },
  exportAs: ["shareButton"],
  features: [i0.ɵɵNgOnChangesFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShareDirective, [{
    type: Directive,
    args: [{
      selector: '[shareButton]',
      exportAs: 'shareButton'
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i1.Meta
    }, {
      type: i2.Platform
    }, {
      type: i3.Clipboard
    }, {
      type: ShareService
    }, {
      type: i0.ChangeDetectorRef
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, {
    shareButtonName: [{
      type: Input,
      args: ['shareButton']
    }],
    autoSetMeta: [{
      type: Input
    }],
    url: [{
      type: Input
    }],
    title: [{
      type: Input
    }],
    description: [{
      type: Input
    }],
    image: [{
      type: Input
    }],
    tags: [{
      type: Input
    }],
    opened: [{
      type: Output
    }],
    closed: [{
      type: Output
    }],
    share: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class ShareModule {
  static withConfig(config) {
    return {
      ngModule: ShareModule,
      providers: [{
        provide: SHARE_BUTTONS_CONFIG,
        useValue: config
      }]
    };
  }
}
ShareModule.ɵfac = function ShareModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ShareModule)();
};
ShareModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ShareModule
});
ShareModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [PlatformModule, ClipboardModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShareModule, [{
    type: NgModule,
    args: [{
      imports: [PlatformModule, ClipboardModule],
      declarations: [ShareDirective],
      exports: [ShareDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { IShareButton, SHARE_BUTTONS, SHARE_BUTTONS_CONFIG, ShareDirective, ShareModule, ShareService, SharerMethod };
