<!-- <section class="main">
  <div class="heading d-flex justify-content-between align-items-center">
    <h2 *ngIf="data?.type == 'doctor'">Are you {{ data?.name || "N/A" }} ?</h2>
    <h2 *ngIf="data?.type == 'hospital'">Is this your Hospital ?</h2>
    <img
      class="cursor-pointer"
      src="assets/images/svg/cross.svg"
      alt=""
      height="14"
      width="14"
      (click)="closeDialog()"
    />
  </div>
  <div class="wrapper">
    <form [formGroup]="signForm" class="field">
      <label for="">Mobile Number</label>
      <input
        type="text"
        maxlength="10"
        placeholder="Mobile Number"
        formControlName="phone"
        oninput="this.value = this.value.replace(/\D/g, '').replace(/^0/,'');"
      />
      <div *ngIf="signForm.get('phone')?.errors">
        <div
          class="form-error"
          *ngIf="(control['phone'].touched || submitted) && control['phone'].errors?.['required']"
        >
          This field is mandatory.
        </div>
        <div
          class="form-error"
          *ngIf="(control['phone'].touched || submitted) && control['phone'].errors?.['minlength']
           &&(!control['phone'].errors?.['required'])"
        >
          Please enter valid phone number.
        </div>
      </div>
    </form>
    <div class="confirm">
      <button (click)="openVerify()">Next</button>
    </div>
    <div class="footer-text">
      <span>*</span>You may receive SMS updates from Nectar
    </div>
  </div>
</section> -->


<div class="modal fade" id="enter_otp_modal" tabindex="-1" aria-labelledby="enter_otp_modalLabel" aria-hidden="true">
  <div [ngClass]="!data?.claimProfile ? 'modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered' : 'modal-dialog modal-md modal-dialog-scrollable modal-dialog-centered'">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Ajay Sharma Appointment History</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeDialog()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6" *ngIf="!data.claimProfile">
            <div class="card">
              <div class="card-header">
                <svg _ngcontent-ng-c187617040="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g _ngcontent-ng-c187617040="" clip-path="url(#clip0_487_1800)"><path _ngcontent-ng-c187617040="" d="M9.99961 18.9997V13.9997H13.9996V18.9997C13.9996 19.5497 14.4496 19.9997 14.9996 19.9997H17.9996C18.5496 19.9997 18.9996 19.5497 18.9996 18.9997V11.9997H20.6996C21.1596 11.9997 21.3796 11.4297 21.0296 11.1297L12.6696 3.59973C12.2896 3.25973 11.7096 3.25973 11.3296 3.59973L2.96961 11.1297C2.62961 11.4297 2.83961 11.9997 3.29961 11.9997H4.99961V18.9997C4.99961 19.5497 5.44961 19.9997 5.99961 19.9997H8.99961C9.54961 19.9997 9.99961 19.5497 9.99961 18.9997Z" fill="#45197C"></path></g>
                  <defs _ngcontent-ng-c187617040="">
                    <clipPath _ngcontent-ng-c187617040="" id="clip0_487_1800"><rect _ngcontent-ng-c187617040="" width="24" height="24" fill="white"></rect></clipPath>
                  </defs>
                </svg>
                {{userType==1?"In-Clinic Appointment":"Video Consultation"}}
              </div>
              <div class="card-body">
                <div class="dr-info-rows">
                  <div class="dr-info-rows-row">
                    <img [src]="userData.profilePic" alt="" />
                    <div class="dr-info-rows-row-text">
                      <h3>{{userData ? userData.name: "dr" }}</h3>
                      <p>{{ specializationList || 'No specialization available' }}</p>
                    </div>
                  </div>
                  <div class="dr-info-rows-row" *ngIf="userType==1">
                    <img src="https://placehold.co/100x100" alt="" />
                    <div class="dr-info-rows-row-text">
                      <h3>{{userData.establishmentDetail.establishmentName}}</h3>
                      
                      <div *ngIf="userType==1">
                        <p>{{userData.establishmentDetail.address.locality +", "+userData.establishmentDetail.address.city +", "+userData.establishmentDetail.address.pincode }}</p>
                        <p>
                          <svg _ngcontent-ng-c187617040="" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g _ngcontent-ng-c187617040="" clip-path="url(#clip0_968_7953)"><path _ngcontent-ng-c187617040="" d="M17.8413 8.82507L11.1829 2.1584C10.5329 1.5084 9.47461 1.5084 8.82461 2.1584L2.14961 8.82507C1.49961 9.47506 1.49961 10.5334 2.14961 11.1834L8.82461 17.8501C9.47461 18.5001 10.5329 18.5001 11.1829 17.8501L17.8413 11.1834C18.4996 10.5251 18.4996 9.47506 17.8413 8.82507ZM11.2496 12.0834V10.0001H8.33294V12.5001H6.66628V9.16673C6.66628 8.7084 7.04128 8.3334 7.49961 8.3334H11.2496V6.25006L14.1663 9.16673L11.2496 12.0834Z" fill="#45197C"></path></g>
                            <defs _ngcontent-ng-c187617040="">
                              <clipPath _ngcontent-ng-c187617040="" id="clip0_968_7953"><rect _ngcontent-ng-c187617040="" width="20" height="20" fill="white"></rect></clipPath>
                            </defs>
                          </svg>
                          Get Directions
                        </p>
                      </div>
                      
                    </div>
                  </div>
                  <div class="dr-info-rows-row">
                    <div class="row align-items-center">
                      <div class="col-6 col-md-6 align-items-center">
                        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" _ngcontent-ng-c1737108717="" aria-hidden="true">
                          <path d="M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16 18H2V7H16V18ZM4 9H9V14H4V9Z" fill="black" _ngcontent-ng-c1737108717=""></path>
                        </svg>
                        On <strong>{{formattedDate}}</strong>
                      </div>
                      <div class="col-6 col-md-6 align-items-center text-end">
                        <svg _ngcontent-ng-c150312255="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g _ngcontent-ng-c150312255="" clip-path="url(#clip0_953_5573)">
                            <path _ngcontent-ng-c150312255="" d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="black"></path>
                            <path _ngcontent-ng-c150312255="" d="M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z" fill="black"></path>
                          </g>
                          <defs _ngcontent-ng-c150312255="">
                            <clipPath _ngcontent-ng-c150312255="" id="clip0_953_5573"><rect _ngcontent-ng-c150312255="" width="24" height="24" fill="white"></rect></clipPath>
                          </defs>
                        </svg>
                        At <strong>{{formattedTime}}</strong>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12"><a (click)="closeDialog()">Change date and time</a></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div [class]="data?.claimProfile ? 'col-lg-12' : 'col-lg-6'">
            <div class="otp-form">
              <h2>{{ data?.claimProfile ? 'Are You ' + data?.name + ' ?': 'To proceed, enter your mobile number.' }}</h2>
              <div class="row">
                <div class="col-lg-12">
                  <label>Mobile Number</label>
                </div>
              </div>
              <form [formGroup]="signForm" class="field">
              <div class="row">
                <div class="col-lg-12" >
                  


                  <div class="input-group">
                    <input [ngStyle]="data.claimProfile ? { 'width': '14%' } : {}" type="text" id="country_code" class="country_code" value="+91" readonly />
                    <input type="text"
                    [ngStyle]="data.claimProfile ? { 'width': '85%' } : {}"                    formControlName="phone"
                    oninput="this.value = this.value.replace(/\D/g, '').replace(/^0/,'');"
                    id="otp" class="otp" placeholder="Mobile Number" />
                  </div>
                  <div *ngIf="signForm.get('phone')?.errors">
                    <div
                      class="form-error"
                      *ngIf="(control['phone'].touched || submitted) && control['phone'].errors?.['required']"
                    >
                      This field is mandatory.
                    </div>
                    <div
                      class="form-error"
                      *ngIf="(control['phone'].touched || submitted) && control['phone'].errors?.['minlength']
                       &&(!control['phone'].errors?.['required'])"
                    >
                      Please enter valid phone number.
                    </div>
                  </div>
                 
                </div>
              </div>
            </form>
              <div class="row">
                <div class="col-lg-12">
                  <button (click)="openVerify()" class="btn btn-primary">Send OTP</button>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <small
                    >By Booking this apportment, you agree to Nectar+ Health <a href="#">Terms and Conditions<span>*</span></a
                    >.</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





<div class="modal fade" id="signupModal" tabindex="-1" aria-labelledby="signupModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered forgot-passwor-modal">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Sign Up</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal('signupModal')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <p>Do you want to signup as a new patient?</p>
          <label class="required" for="updatedValue">Full Name</label>
          <input type="text" class="form-control" [(ngModel)]="name" />
          <label class="required" for="updatedValue">Mobile Number</label>
          <input type="text" class="form-control" [(ngModel)]="phone" />
        </div>
        <div class="modal-footer">

          <button type="button" class="btn btn-outline-secondary btn-block" (click)="closeModal('signupModal')">Cancel</button>
          <button type="button" class="btn btn-primary btn-block" (click)="signup()">Continue</button>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="otp_verification_modal" tabindex="-1" aria-labelledby="otp_verification_modalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Verify OTP</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal('otp_verification_modal')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body verify-otp-modal">
        <div class="form-group">
          <!-- <label>{{ fieldToEdit === "phone" ? "Phone" : "Email" }}: {{ updatedValue }}</label> -->
          <p class="text-success">Enter the verification cdoe sent to your mobile...</p>
          <label class="updated-phone"
            >{{ phone }}
            <button type="button" class="btn btn-edit" (click)="changePhoneOrEmail()">
              <img src="assets/images/svg/edit.svg" alt="" /></button
          ></label>
          <label>OTP</label>
          <input type="text" class="form-control" [(ngModel)]="otp" placeholder="Please enter the 6 digit OTP here to verify" />
          <p>Didn't receive OTP? <a href="#" class="text-primary">Resend OTP</a></p>
        </div>
        <!-- <button type="button" class="btn btn-link" (click)="changePhoneOrEmail()">Change {{ fieldToEdit === "phone" ? "Phone" : "Email" }}</button> -->

        <button type="button" class="btn btn-primary btn-block" (click)="verifyOTP()">Verify</button>
      </div>
    </div>
  </div>
</div>






