<div class="appoinment-dates" style="position: relative">
  <div class="visit-appointment">
    <!-- <img loading="lazy" src="assets/images/pharmacy 1@2x.png" alt="" /> -->
    <p class="ml-0">Select the type of appointment</p>
  </div>

  <ul class="tabs_bar_choose">
    <li (click)="selectTab(1)" [class.active]="selectedTab === 1" class="px-2" >
      <div class="rounded-circle" [ngStyle]="{'border': hideFlagTab === 1 ? '3px solid #acacac' : ''}">
        <div [ngClass]="{ 'active-checkbox': selectedTab === 1 }" class="checkbox-round" autocomplete="on"></div>
      </div>
      <p>In Clinic</p>
    </li>
    <li (click)="selectTab(2)" [class.active]="selectedTab === 2" class="px-2" >
      <div class="rounded-circle" [ngStyle]="{'border': hideFlagTab === 2 ? '3px solid #acacac' : ''}">
        <div [ngClass]="{ 'active-checkbox': selectedTab === 2 }" class="checkbox-round"></div>
      </div>
      <p>Video</p>
    </li>
  </ul>

  <!-- Divs to switch -->
  <!--=====how can i get -->
  <div *ngIf="selectedTab === 1">
    <div class="doctor-detail">
      <div class="hospital-name">
        <p>{{ doctoar_name }} ({{ establishmentDetail[0]?.establishmentName }})</p>
        <img src="assets/images/Vector.png" alt="" class="cursor-pointer" (click)="toggle = !toggle" *ngIf="establishmentDetail.length > 1" />
      </div>
      <div class="rating">
        <star-rating [rating]="establishmentDetail[0]?.rating || 0" [step]="0.1" [showHalfStars]="true" [readOnly]="true"></star-rating>
        <span class="star">{{ (establishmentDetail[0]?.rating | number : "1.1-1") || "0" }} </span>
        <span class="total"> /5 </span>
        <span class="review">({{ establishmentDetail[0]?.totalReview || "0" }} review)</span>
      </div>
      <div class="other-detail">
        <div class="address">
          <p>
            {{ establishmentDetail[0]?.address?.locality ? establishmentDetail[0]?.address?.locality +" ," : "" || "" }}
            {{ establishmentDetail[0]?.address?.city || "" }}
            {{ establishmentDetail[0]?.address?.pincode || "" }}
          </p>
          <span
            class="cursor-pointer"
            (click)="
      gService.redirectToGoogleMaps(
        establishmentDetail[0]?.location?.coordinates?.[1] || 28.6448,
        establishmentDetail[0]?.location?.coordinates?.[0] || 77.216721
      )
    "
          >
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2">
              <g id="Directions" clip-path="url(#clip0_669_932)">
                <path id="Vector" d="M16.2827 8.46742L9.53273 1.71742C9.24023 1.42492 8.76773 1.42492 8.47523 1.71742L1.72523 8.46742C1.43273 8.75992 1.43273 9.23242 1.72523 9.52492L8.47523 16.2749C8.76773 16.5674 9.24023 16.5674 9.53273 16.2749L16.2827 9.52492C16.5752 9.23992 16.5752 8.76742 16.2827 8.46742ZM10.5002 10.8749V8.99992H7.50023V11.2499H6.00023V8.24992C6.00023 7.83742 6.33773 7.49992 6.75023 7.49992H10.5002V5.62492L13.1252 8.24992L10.5002 10.8749Z" fill="#45197C" />
              </g>
              <defs>
                <clipPath id="clip0_669_932">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>

            Get Direction</span
          >
        </div>
        <div class="doctor-fees ms-2" *ngIf="establishmentDetail[0]?.consultationFees!=-1 && establishmentDetail[0]?.consultationFees!=0 && establishmentDetail[0]?.consultationFees!=null && establishmentDetail[0]?.consultationFees!=undefined">
          <span>₹ {{ establishmentDetail[0]?.consultationFees || 0 }}</span>
          <p>Consultation Fees</p>
        </div>
        <div class="doctor-fees ms-2" *ngIf="establishmentDetail[0]?.videoConsultationFees!=-1 && establishmentDetail[0]?.videoConsultationFees!=0 &&establishmentDetail[0]?.videoConsultationFees!=null && establishmentDetail[0]?.videoConsultationFees!=undefined">
          <span>₹ {{ establishmentDetail[0]?.videoConsultationFees || 0 }}</span>
          <p>Video Consultation Fees</p>
          
        </div>
      </div>
    </div>
    <div class="carousel-container">
      <owl-carousel-o [options]="customOptions" class="caraousel" (translated)="carouselChange($event)" #carousel>
        <ng-container *ngFor="let name of dates; let i = index">
          <ng-template carouselSlide [id]="name" [width]="120">
            <p (click)="getClickedDate(name)" class="cursor-pointer">
              {{ name }}
            </p>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    <!---===========Sloat available Timing charts============-->
    <div class="scheduled-appointment">
      <!-- <p *ngIf="availableSlots" class="text-center soltsCount">
        {{ availableSlots || 0 }} Slots Available
      </p> -->
      <div class="morningappointment">
        <div class="slots">
          <div class="routine" *ngIf="morningSlots.length">Morning<img loading="lazy" src="assets/images/sunrise 1.png" alt="morning" /></div>
          <p *ngIf="availableSlots" class="soltsCount">{{ availableSlots }} Slots Available</p>
        </div>
        <p *ngIf="!availableSlots && !(morningSlots.length || noonSlots.length || eveSlots.length)" class="text-center text-danger text-18">No Slots Available</p>
        <div class="timing" *ngIf="morningSlots.length">
          <div class="btn-wrap" *ngFor="let timings of morningSlots">
            <button (click)="selectTime(timings, 'morning')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
      <div class="morningappointment mt-3" *ngIf="noonSlots.length">
        <div class="slots">
          <div class="routine">Afternoon<img src="assets/images/sunset 1.png" style="position: relative; top: -6px" alt="noon" /></div>
        </div>
        <div class="timing">
          <div class="btn-wrap" *ngFor="let timings of noonSlots">
            <button (click)="selectTime(timings, 'afternoon')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
      <div class="morningappointment mt-3" *ngIf="eveSlots.length">
        <div class="slots">
          <div class="routine">Evening<img src="assets/images/night-mode 1.svg" height="24" width="24" alt="night" /></div>
        </div>
        <div class="timing">
          <div *ngFor="let timings of eveSlots" class="btn-wrap">
            <button (click)="selectTime(timings, 'evening')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedTab === 2">
    <div class="doctor-detail">
      <div class="hospital-name">
        <p>{{ doctoar_name }} ({{ establishmentDetail[0]?.establishmentName }})</p>
        <img src="assets/images/Vector.png" alt="" class="cursor-pointer" (click)="toggle = !toggle" *ngIf="establishmentDetail.length > 1" />
      </div>
      <div class="rating">
        <star-rating [rating]="establishmentDetail[0]?.rating || 0" [step]="0.1" [showHalfStars]="true" [readOnly]="true"></star-rating>
        <span class="star">{{ (establishmentDetail[0]?.rating | number : "1.1-1") || "0" }} </span>
        <span class="total"> /5 </span>
        <span class="review">({{ establishmentDetail[0]?.totalReview || "0" }} review)</span>
      </div>
      <div class="other-detail">
        <!-- <div class="address">
          <p>
            {{ establishmentDetail[0]?.address?.locality || "N/A" }},
            {{ establishmentDetail[0]?.address?.city || "" }}
            {{ establishmentDetail[0]?.address?.pincode || "" }}
          </p>
          <span class="cursor-pointer" (click)="
      gService.redirectToGoogleMaps(
        establishmentDetail[0]?.location?.coordinates?.[1] || 28.6448,
        establishmentDetail[0]?.location?.coordinates?.[0] || 77.216721
      )
    ">
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2">
<g id="Directions" clip-path="url(#clip0_669_932)">
<path id="Vector" d="M16.2827 8.46742L9.53273 1.71742C9.24023 1.42492 8.76773 1.42492 8.47523 1.71742L1.72523 8.46742C1.43273 8.75992 1.43273 9.23242 1.72523 9.52492L8.47523 16.2749C8.76773 16.5674 9.24023 16.5674 9.53273 16.2749L16.2827 9.52492C16.5752 9.23992 16.5752 8.76742 16.2827 8.46742ZM10.5002 10.8749V8.99992H7.50023V11.2499H6.00023V8.24992C6.00023 7.83742 6.33773 7.49992 6.75023 7.49992H10.5002V5.62492L13.1252 8.24992L10.5002 10.8749Z" fill="#45197C"/>
</g>
<defs>
<clipPath id="clip0_669_932">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>

    Get Direction</span>
        </div> -->
        <div class="doctor-fees" *ngIf="establishmentDetail[0]?.consultationFees!=-1 && establishmentDetail[0]?.consultationFees!=0 && establishmentDetail[0]?.consultationFees!=null && establishmentDetail[0]?.consultationFees!=undefined">
          <span>₹ {{ establishmentDetail[0]?.consultationFees || 0 }}</span>
          <p>Consultation Fees</p>
        </div>
        <div class="doctor-fees"  *ngIf="establishmentDetail[0]?.videoConsultationFees!=-1 && establishmentDetail[0]?.videoConsultationFees!=0 && establishmentDetail[0]?.videoConsultationFees!=null && establishmentDetail[0]?.videoConsultationFees!=undefined">
          <span>₹ {{ establishmentDetail[0]?.videoConsultationFees || 0 }}</span>
          
          <p>Video Consultation Fees</p>
        </div>
      </div>
    </div>
    <div class="carousel-container">
      <owl-carousel-o [options]="customOptions" class="caraousel" (translated)="carouselChange($event)" #carousel>
        <ng-container *ngFor="let name of dates; let i = index">
          <ng-template carouselSlide [id]="name" [width]="120">
            <p (click)="getClickedDate(name)" class="cursor-pointer">
              {{ name }}
            </p>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    <!---===========sloat available Timing cahrts============-->
    <div class="scheduled-appointment">
      <!-- <p *ngIf="availableSlots" class="text-center soltsCount">{{ availableSlots || 0 }} Slots Available</p> -->
      <div class="morningappointment">
        <div class="slots">
          <div class="routine" *ngIf="morningSlots.length">Morning<img loading="lazy" src="assets/images/sunrise 1.png" alt="morning" /></div>
          <p *ngIf="availableSlots" class="soltsCount">{{ availableSlots }} Slots Available</p>
        </div>
        <p *ngIf="!availableSlots && !(morningSlots.length || noonSlots.length || eveSlots.length)" class="text-center text-danger text-18">No Slots Available</p>
        <div class="timing" *ngIf="morningSlots.length">
          <div class="btn-wrap" *ngFor="let timings of morningSlots">
            <button (click)="selectTime(timings, 'morning')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
      <div class="morningappointment mt-3" *ngIf="noonSlots.length">
        <div class="slots">
          <div class="routine">Afternoon<img src="assets/images/sunset 1.png" style="position: relative; top: -6px" alt="noon" /></div>
        </div>
        <div class="timing">
          <div class="btn-wrap" *ngFor="let timings of noonSlots">
            <button (click)="selectTime(timings, 'afternoon')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
      <div class="morningappointment mt-3" *ngIf="eveSlots.length">
        <div class="slots">
          <div class="routine">Evening<img src="assets/images/night-mode 1.svg" height="24" width="24" alt="night" /></div>
        </div>
        <div class="timing">
          <div *ngFor="let timings of eveSlots" class="btn-wrap">
            <button (click)="selectTime(timings, 'evening')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div></div>
</div>

<div class="other-hospital w-100" *ngIf="toggle" nectarClickOutside style="position: absolute; top: 180px" (clickOutside)="toggle = !toggle">
  <div *ngFor="let item of establishmentDetail; let i = index" class="cursor-pointer border-line" (click)="selectHospital(item)">
    <p *ngIf="i != 0">{{ doctoar_name }} {{ item?.establishmentName }}</p>
    <div class="rating" *ngIf="i != 0">
      <star-rating [rating]="item?.rating || 0" [step]="0.1" [showHalfStars]="true" [readOnly]="true"></star-rating>
      <span class="star"> {{ (item?.rating | number : "1.1-1") || "0" }} </span>
      <span class="total"> /5 </span>
      <span class="review">({{ item?.totalReview || "0" }} review)</span>
    </div>
    <div class="other-detail" *ngIf="i != 0">
      <div class="address">
        <p>
          {{ item?.address?.locality ? item?.address?.locality+ " ,":""   }}
          {{ item?.address?.city || "" }}
          {{ item?.address?.pincode || "" }}
        </p>
        <span
          class="cursor-pointer"
          (click)="
      gService.redirectToGoogleMaps(
        item?.location?.coordinates?.[1] || 28.6448,
        item?.location?.coordinates?.[0] || 77.216721
      )
    "
        >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-1">
            <g id="Directions" clip-path="url(#clip0_669_932)">
              <path id="Vector" d="M16.2827 8.46742L9.53273 1.71742C9.24023 1.42492 8.76773 1.42492 8.47523 1.71742L1.72523 8.46742C1.43273 8.75992 1.43273 9.23242 1.72523 9.52492L8.47523 16.2749C8.76773 16.5674 9.24023 16.5674 9.53273 16.2749L16.2827 9.52492C16.5752 9.23992 16.5752 8.76742 16.2827 8.46742ZM10.5002 10.8749V8.99992H7.50023V11.2499H6.00023V8.24992C6.00023 7.83742 6.33773 7.49992 6.75023 7.49992H10.5002V5.62492L13.1252 8.24992L10.5002 10.8749Z" fill="#45197C" />
            </g>
            <defs>
              <clipPath id="clip0_669_932">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>

          Get Direction</span
        >
      </div>
      <div class="doctor-fees" *ngIf="i != 0 &&  item?.consultationFees!=-1 && item?.consultationFees!=0 && item?.consultationFees!=null && item?.consultationFees!=undefined">
        ₹ {{ item?.consultationFees || 0 }} Consultation Fees
      </div> 
      <div class="doctor-fees" *ngIf="i != 0 &&  item?.videoConsultationFees!=-1 && item?.videoConsultationFees!=0 && item?.videoConsultationFees!=null && item?.videoConsultationFees!=undefined">
        
        ₹ {{ item?.videoConsultationFees || 0 }} Video Consultation Fees
      </div>
    </div>
  </div>
</div>
