import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { API_ENDPOINTS } from "src/app/config/api.constant";
import { ApiService } from "src/app/services/api.service";
import { EventService } from "src/app/services/event.service";
import { FormatTimeService } from "src/app/services/format-time.service";
import { GoogleMapsService } from "src/app/services/google-maps.service";
import { ClinicappointmentComponent } from "../clinicappointment/clinicappointment.component";
import { DatePipe } from "@angular/common";
import { ImageViewModalComponent } from "../../image-view-modal/image-view-modal.component";
import { BottomSheetClinicVisitComponent } from "../bottom-sheet-clinic-visit/bottom-sheet-clinic-visit.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "nectar-about-section",
  templateUrl: "./about-section.component.html",
  styleUrls: ["./about-section.component.scss"],
})
export class AboutSectionComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    private eventService: EventService,
    private router: Router,
    private formatTime: FormatTimeService,
    public gService: GoogleMapsService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private _bottomSheet: MatBottomSheet,
    private commonService: CommonService
  ) {}
  @Input() id: any;
  @Output() backdrop = new EventEmitter();
  deviceWidth: any;
  ngOnInit(): void {
    this.deviceWidth = this.commonService.gettingWinowWidth();
    this.eventService.getEvent("doctor-route").subscribe((res: any) => {
      if (res) {
        this.id = res;
        this.getAbout();
      }
    });
    this.getAbout();
  }
  aboutData: any;
  getAbout() {

    console.log('heeeeeeeeeeeeeeelllo')
    if (!this.id) return;

    this.apiService
      .get(`${API_ENDPOINTS.patient.doctorDetail}`, {
        doctorId: this.id,
      })
      .subscribe((res: any) => {
        this.aboutData = res?.result[0];
        this.aboutData?.establishmentmaster.forEach((element: any) => {
          let days = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
          if (element.establishmenttiming[0]) {
            Object.keys(element.establishmenttiming[0]).forEach((key) => {
              if (!days.includes(key))
                delete element.establishmenttiming[0][key];
            });
          }
          element.establishmenttiming = element.establishmenttiming[0];
          element.establishmenttiming = this.formatTime.dateTimeConversion(
            element.establishmenttiming
          );
        });


      });
      console.log('estab list ', this.aboutData)
  }
  bookAppoint(item: any) {
    if (this.deviceWidth > 1024) {
      this.backdrop.emit(item);
      this.eventService.broadcastEvent("hospital-data", item);
    } else {
      let date = this.datePipe.transform(new Date(), "EEE, d MMM");
      this.dialog.open(ClinicappointmentComponent, {
        width: "490px",
        panelClass: "yespost",
        data: {
          date: date,
          id: this.id,
          establishmentId: item?._id,
        },
        autoFocus: false,
      });
    }
  }

  viewHospital(data: any) {
    if(data.consultationFees!=-1){
      const city = data?.address?.city.split(" ").join("-").toLowerCase();
      this.router.navigate([`${city}/hospital/${data?.profileSlug}`]);
    }
  
  }

  viewImage(url: any) {
    this.dialog.open(ImageViewModalComponent, {
      data: url,
      autoFocus: false,
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  openBottomSheet(data: any = {}) {
    this._bottomSheet.open(BottomSheetClinicVisitComponent, {
      data: {
        newsId: this.id,
        establishmentIds: data?._id,
      },
    });
    this.eventService.broadcastEvent("hospital-data", data);
  }
}
