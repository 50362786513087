import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "src/app/services/api.service";
import { GoogleMapsService } from "src/app/services/google-maps.service";
import { EventService } from "src/app/services/event.service";
import { Subject, debounceTime, distinctUntilChanged, fromEvent } from "rxjs";
import { LocalStorageService } from "src/app/services/storage.service";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { SearchSuggestionsMobileComponent } from "../search-suggestions-mobile/search-suggestions-mobile.component";
import { API_ENDPOINTS } from "src/app/config/api.constant";
import { CommonService } from "src/app/services/common.service";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";

@Component({
  selector: "nectar-global-search",
  templateUrl: "./global-search.component.html",
  styleUrls: ["./global-search.component.scss"],
})
export class GlobalSearchComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("symptoms") symptoms: ElementRef;
  @ViewChild("location", { static: true }) location: ElementRef;
  @Input() type = "header";
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  deviceWidth: any;
  searchStateSubject: Subject<string> = new Subject();
  citySearchSubject: Subject<string> = new Subject();
  states = [];
  cities = [];
  filteredStates = [];
  filteredCities = [];

  constructor(
    private apiService: ApiService,
    private router: Router,
    private gService: GoogleMapsService,
    private eventService: EventService,
    private localStorage: LocalStorageService,
    private bottomSheet: MatBottomSheet,
    private commonService: CommonService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private _document: Document
  ) { }
  placePredictions: any[] = [];
  searchSubject: Subject<string> = new Subject();
  placeService: any;
  ngOnInit(): void {
    this.deviceWidth = this.commonService.gettingWinowWidth();
    this.getCurrentCity();
    this.getSearchValue();
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.location.nativeElement.value = "Delhi";
    }
  }
  getSearchData() {
    let obj: any = {};
    if (this.symptoms.nativeElement.value) {
      obj.filter = this.commonService.replaceSpaceWithHyphen(
        this.symptoms.nativeElement.value
      );
    }
    if (this.location.nativeElement.value) {
      let location =
        this.selectedCity ||
        this.currentCity ||
        this.selectedState ||
        this.location.nativeElement.value;
      obj.location = this.commonService.replaceSpaceWithHyphen(location);
    }
    if (this.selectedSubLocality) {
      this.selectedSubLocality = this.commonService.replaceSpaceWithHyphen(
        this.selectedSubLocality
      );
    }
    if (obj.location && obj.filter) {
      this.router.navigate([
        `/${obj.location}/${obj.filter}/${this.selectedSubLocality || ""}`,
      ]);
    }
  }
  currentCity: any;
  getCurrentCity() {
    this.apiService.getCitiesByCountry('india').subscribe(response => {
      this.cities = response.data;
      this.filteredCities = [...this.cities];
      this.currentCity = "Delhi";
      this.localStorage.setItem("city", this.currentCity);
      this.location.nativeElement.value = this.currentCity;
      if (!this.symptoms.nativeElement.value) {
        this.symptoms.nativeElement.focus();
        this.specialization();
      }
      this.citySearchSubject
        .pipe(
          debounceTime(300),
          distinctUntilChanged()
        )
        .subscribe((inputValue: string) => {
          this.filteredCities = this.cities.filter(state =>
            state.toLowerCase().includes(inputValue.toLowerCase())
          );
        });
    });
    
    // this.apiService.getstatesByCountry('india').subscribe(response => {
    //   this.states = response.data.states.map((state: any) => state.name);
    //   this.filteredStates = [...this.states];
    //   this.searchStateSubject
    //     .pipe(
    //       debounceTime(300),
    //       distinctUntilChanged()
    //     )
    //     .subscribe((inputValue: string) => {
    //       this.filteredStates = this.states.filter(city =>
    //         city.toLowerCase().includes(inputValue.toLowerCase())
    //       );
    //     });
    // });
  }

  onSearch(event: any) {
    const value = event.target.value;
    if (value) {
      this.searchSubject.next(value);
    } else {
      this.placePredictions = [];
    }
  }

  onCitySearch(event: any): void {
    const inputValue = event.target.value;
    this.citySearchSubject.next(inputValue); // Trigger the subject
  }
  getSearchValue() {
    this.searchSubject.pipe(debounceTime(300)).subscribe((res: any) => {
      this.onSearchInput(res);
    });
  }
  onSearchInput(value: string) {
    if (value?.length) {
      const autocompleteService = new google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: value,
          componentRestrictions: { country: "in" },
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.placePredictions = predictions;
          } else {
            this.placePredictions = [];
          }
        }
      );
    } else {
      this.placePredictions = [];
    }
  }
  selectedCity: string;
  selectedState: string;
  selectedSubLocality: string;
  getPlace(data: any) {
    this.localStorage.setItem("search-address", data?.description);
    this.gService.getAddressComponents(data?.place_id).then((res: any) => {
      res?.address_components.forEach((e: any) => {
        if (e.types.includes("administrative_area_level_3")) {
          this.selectedCity = e.long_name;
        } else if (
          e.types.includes("sublocality_level_1") ||
          e.types.includes("sublocality")
        ) {
          this.selectedSubLocality = e.long_name;
        }
      });
      let lng = res?.geometry?.location?.lng();
      let lat = res?.geometry?.location?.lat();
      this.localStorage.setItem("coordinates", JSON.stringify([lng, lat]));
      if (!this.symptoms.nativeElement.value) {
        this.symptoms.nativeElement.focus();
        this.specialization();
      }
    });
  }

  setSelectedCity(city: string) {
    this.selectedCity = city;
  }
  setSelectedState(state: string) {
    this.selectedState = state;
  }

  specialization() {
    this.apiService
      .get(API_ENDPOINTS.MASTER.specialization, "")
      .subscribe((res: any) => {
        this.specialityOptions = res?.result?.data;
      });
  }

  specialityOptions: any = [];
  servicesOptions: any = [];
  doctorsOptions: any = [];
  hospitalsOptions: any = [];
  clinicOptions: any = [];

  getSuggestionList(event: any) {
    if (event.target.value && event.target.value.length > 2) {
      let e = event.target.value;
      this.apiService.searchSuggestions(e).subscribe((res: any) => {
        this.specialityOptions =
          res?.result?.data?.specializationData?.data || [];
        this.servicesOptions = res?.result?.data?.procedureData?.data || [];
        this.hospitalsOptions = res?.result?.data?.hospitalData?.data || [];
        this.doctorsOptions = res?.result?.data?.doctorData?.data || [];
        this.clinicOptions = res?.result?.data?.clinicData?.data || [];
      });
    }
  }
  closeSuggestion(data: string) {
    if (data == "symptomps") {
      this.symptoms.nativeElement.value = "";
    } else {
      this.location.nativeElement.value = "";
    }
  }

  getSuggestion(data: any, type: string) {
    this.eventService.broadcastEvent("view-doctor", true);
    const city = this.commonService.replaceSpaceWithHyphen(data?.address?.city);
    if (type == "doctor") {
      this.router.navigate([`${city}/doctor/${data?.doctorProfileSlug}`]);
    } else if (type == "hospital") {
      this.router.navigate([
        `${city}/hospital/${data?.establishmentProfileSlug}`,
      ]);
    }
  }

  focusOnLocation() {
    if (!this.location.nativeElement.value) {
      setTimeout(() => {
        this.location.nativeElement.focus();
      }, 0);
    }
  }

  scrollToTop() {
    scrollTo(0, 0);
  }

  scrollTop: any;
  bottomReached: boolean = false;
  @HostListener("window:scroll", [])
  onWindowScroll() {
    const scroll$ = fromEvent(window, "scroll").pipe(debounceTime(100));
    scroll$.subscribe(() => {
      const scrollTop =
        window.scrollY ||
        this._document.documentElement.scrollTop ||
        this._document.body.scrollTop ||
        0;
      this.scrollTop = scrollTop;

      const scrollHeight = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = this._document.documentElement.scrollHeight;

      if (scrollHeight + windowHeight > documentHeight - 700) {
        this.bottomReached = true;
      } else {
        this.bottomReached = false;
      }
    });
  }

  openBottomSheet(data: any) {
    if (this.deviceWidth < 767) {
      let obj: any = { type: data };
      const sheetRef = this.bottomSheet.open(SearchSuggestionsMobileComponent, {
        data: obj,
        panelClass: "search-bottom-sheet",
      });
      sheetRef.afterDismissed().subscribe((data: any) => {
        if (data?.symptoms) {
          this.symptoms.nativeElement.value = data?.symptoms;
        }
        if (data?.location) {
          this.location.nativeElement.value = data?.location;
        }
      });
    }
  }

  focusinmethod() {
    let b = this._document.body;
    b.style.overflow = "hidden";
  }
  focusoutmethod() {
    let b = this._document.body;
    b.style.overflow = "auto";
  }

  ngOnDestroy(): void {
    this.focusoutmethod();
  }
}
