import { environment } from "src/environments/environment";

export const API_ENDPOINTS = {
  auth: {
    login: `${environment.baseUrl}v1/auth/login`,
    register: `${environment.baseUrl}v1/auth/signup`,
    verifyOtp: `${environment.baseUrl}v1/auth/verify-otp`,
    resendOtp: `${environment.baseUrl}v1/auth/resend-otp`,
    logout: `${environment.baseUrl}v1/auth/logout`,
    otpViaCall: `${environment.baseUrl}v1/auth/get-otp-via-call`,
    newRegister: `${environment.baseUrl}v1/registration/register`,
    resetPassword: `${environment.baseUrl}v1/reset-password/reset-password`,
    // newLogin: `${environment.baseUrl}v1/auth/get-otp-via-call`,

  },
  new: {
    login: `${environment.baseUrl}v1/registration/login`,
    register: `${environment.baseUrl}v1/auth/signup`,
    verifyOtp: `${environment.baseUrl}v1/registration/verify-otp`,
    resendOtp: `${environment.baseUrl}v1/auth/resend-otp`,
    logout: `${environment.baseUrl}v1/auth/logout`,
    otpViaCall: `${environment.baseUrl}v1/auth/get-otp-via-call`,
    newRegister: `${environment.baseUrl}v1/registration/register`,


    EditProfilePhoneOtp: `${environment.baseUrl}v1/registration/changePhone`,
    EditProfileEmailOtp: `${environment.baseUrl}v1/registration/register`,

    verifyProfilePhoneOtp: `${environment.baseUrl}v1/registration/changePhoneVerify`,
    verifyProfileEmailOtp: `${environment.baseUrl}v1/registration/register`,
    // newLogin: `${environment.baseUrl}v1/auth/get-otp-via-call`,


    forgetPhone: `${environment.baseUrl}v1/registration/forgetPhone`,
    verifyForgetPhone: `${environment.baseUrl}v1/registration/verifyForgetPhone`,
    changePasswordForgetPhone: `${environment.baseUrl}v1/registration/changePasswordForgetPhone`,
    updateUserSteps: `${environment.baseUrl}v1/setting/updateUserSteps`,




  },
  doctor: {
    getFeedback: `${environment.baseUrl}v1/feedback/get-feedback`,
    replyFeedback: `${environment.baseUrl}v1/feedback/reply-feedback`,
    likeFeedback: `${environment.baseUrl}v1/feedback/like-feedback`,
    

    getCalendarData: `${environment.baseUrl}v1/doctor/get-calender`,
    deleteEstablishment: `${environment.baseUrl}v1/doctor/doctor-delete-establishment`,
    doctorCancelAppointment: `${environment.baseUrl}v1/doctor/doctor-cancel-appointment`,
    doctorCompleteAppointment: `${environment.baseUrl}v1/doctor/doctor-complete-appointment`,
    doctorAddEstablishment: `${environment.baseUrl}v1/doctor/doctor-add-establishment`,
    searchDoctors: `${environment.baseUrl}v1/doctor/get-all-doctors`,
    topRatedDoctors: `${environment.baseUrl}v1/doctor/get-all-top-rated-doctors`,
    criticalIssues: `${environment.baseUrl}v1/doctor/get-all-specializations `,
    pateintReview: `${environment.baseUrl}v1/appointment/get-all-appointment-feedbacks  `,
    doctorByCity: `${environment.baseUrl}v1/doctor/get-all-doctors-by-city`,
    updateProfile: `${environment.baseUrl}v1/doctor/doctor-update-profile`,
    updatemedicalverificationProfile: `${environment.baseUrl}v1/doctor/doctor-update-medical-verification`,
    updateDoctorProfile: `${environment.baseUrl}v1/setting/profile`,
    getEstablishmentProof: `${environment.baseUrl}v1/doctor/getEstablishmentProof`,
    getProfile: `${environment.baseUrl}v1/doctor/get-doctor-profile`,
    cancelAppointment: `${environment.baseUrl}v1/doctor/doctor-cancel-appointment`,
    completeAppointment: `${environment.baseUrl}v1/doctor/doctor-complete-appointment`,
    patientList: `${environment.baseUrl}v1/patient/doctor/list`,
    patientProfile: `${environment.baseUrl}v1/patient/doctor/record`,
    editappointment: `${environment.baseUrl}v1/doctor/doctor-edit-appointment`,
    deleteappointment: `${environment.baseUrl}v1/doctor/doctor-delete-appointment`,
    addEstablishment: `${environment.baseUrl}v1/doctor/doctor-add-establishment`,
    establishmentDetail: `${environment.baseUrl}v1/doctor/establishment-data`,
    editEstablishmentDetail: `${environment.baseUrl}v1/doctor/doctor-edit-establishment`,
    establishmentRequestList: `${environment.baseUrl}v1/doctor/doctor-establishment-request`,
    changeEstablishmentStatus: `${environment.baseUrl}v1/doctor/doctor-accept-establishment`,
    appointmentCount: `${environment.baseUrl}v1/doctor/doctor-appointment-dashboard`,
    appointmentList: `${environment.baseUrl}v1/doctor/doctor-appointment-list`,
    uniqueDoctorregistration: `${environment.baseUrl}v1/common/medical-registration-exist`,
    settingList: `${environment.baseUrl}v1/setting/list`,
    settingListPatchValue: `${environment.baseUrl}v1/setting/record`,
    faqList: `${environment.baseUrl}v1/faq/all-faq`,
    addfaqList: `${environment.baseUrl}v1/faq`,
    social: `${environment.baseUrl}v1/master/social-media`,
    addVideos: `${environment.baseUrl}v1/video`,
    deleteDoctor: `${environment.baseUrl}v1/doctor/delete-profile`,
    specailization: `${environment.baseUrl}v1/doctor/specialization-list`,
    establishmentList: `${environment.baseUrl}v1/doctor/doctor-establishment-list`,
    patientAppointmentList: `${environment.baseUrl}v1/patient/doctor/appointment/list`,
    procedures: `${environment.baseUrl}v1/doctor/procedure`,
  },
  patient: {
    clinicVisitAppointment: `${environment.baseUrl}v1/appointment/available-slots`,
    bookAppointment: `${environment.baseUrl}v1/appointment/book-appointment`,
    rescheduleAppointment: `${environment.baseUrl}v1/appointment/appointment-rescheduling`,
    cancelAppointment: `${environment.baseUrl}v1/appointment/appointment-cancellation`,
    getAppoinment: `${environment.baseUrl}v1/appointment/find-appointment`,
    getSlotsReschedule: `${environment.baseUrl}v1/appointment/appointment-rescheduling-status`,
    doctorDetail: `${environment.baseUrl}v1/doctor/profile`,
    doctorVideos: `${environment.baseUrl}v1/video/list`,
    doctorFAQ: `${environment.baseUrl}v1/faq/all-faq`,
    doctorService: `${environment.baseUrl}v1/doctor/speciality`,
    doctorReviews: `${environment.baseUrl}v1/doctor/reviews`,
    hospitalProfile: `${environment.baseUrl}v1/hospital/profile`,
    hospitalProcedure: `${environment.baseUrl}v1/doctor/establishment-profile`,
    specialityFirstLetter: `${environment.baseUrl}v1/doctor/speciality-first-letter-list`,
    specialityWithCount: `${environment.baseUrl}v1/doctor/establishment-speciality-list`,
    hospitalServices: `${environment.baseUrl}v1/hospital/service/list`,
    hospitalReviews: `${environment.baseUrl}v1/hospital/review/list`,
    addFeedback: `${environment.baseUrl}v1/feedback/add-feedback`,
    myAppointments: `${environment.baseUrl}v1/appointment/appointment-history`,
    myMedicalRecords: `${environment.baseUrl}v1/medical-reports/all-medical-reports`,
    MedicalRecord: `${environment.baseUrl}v1/medical-reports`,
    viewMedicalRecord: `${environment.baseUrl}v1/medical-reports`,
    myFeedbacks: `${environment.baseUrl}v1/patient/feedback/list`,
    deleteFeedback: `${environment.baseUrl}v1/admin/feedbacks`,
    deletMedicalRecord: `${environment.baseUrl}v1/medical-reports`,
    faq: `${environment.baseUrl}v1/faq/all-faq`,
    getEstablishmentDetail: `${environment.baseUrl}v1/patient/establishment-details`,
    getUserDetail: `${environment.baseUrl}v1/patient/profile`,
    getSearchSuggestion: `${environment.baseUrl}v1/patient/homepage`,
    getAllHospitals: `${environment.baseUrl}v1/hospital/find-hospital-list`,
    getDoctorsUnderHospital: `${environment.baseUrl}v1/hospital/find-doctor-list`,
    contactUs: `${environment.baseUrl}v1/contactus`,
    getSurgeryList: `${environment.baseUrl}v1/surgery/all-surgery`,
    surgeryEnquiry: `${environment.baseUrl}v1/surgery/enquire`,
    viewSurgery: `${environment.baseUrl}v1/surgery/find`,
    resendEnquiry: `${environment.baseUrl}v1/surgery/resend-otp`,
    verifyEnquiry: `${environment.baseUrl}v1/surgery/verify-otp`,
    getAppointmentCountsDaily: `${environment.baseUrl}v1/appointment/available-slots-count`,
    faqSurgeryWise: `${environment.baseUrl}v1/surgery-faq/all-faq`,
    getDepartments: `${environment.baseUrl}v1/surgery/department`,
    getSurgeryUnderDepartment: `${environment.baseUrl}v1/surgery/department-surgery`,
    criticalIssuesHome: `${environment.baseUrl}v1/master/little-critical-issue`,
  },
  hospital: {
    getProfile: `${environment.baseUrl}v1/hospital/admin`,
    updateProfile: `${environment.baseUrl}v1/hospital/profile`,
    getCalendarData: `${environment.baseUrl}v1/hospital/calendar`,
    changeAppointmentStatus: `${environment.baseUrl}v1/hospital/appointment`,
    appointmentList: `${environment.baseUrl}v1/hospital/appointment`,
    doctorList: `${environment.baseUrl}v1/hospital/doctor-list`,
    rescheduleAppointment: `${environment.baseUrl}v1/hospital/appointment`,
    patientList: `${environment.baseUrl}v1/patient/hospital`,
    patientHistory: `${environment.baseUrl}v1/patient/hospital/history`,
    doctorProfile: `${environment.baseUrl}v1/hospital/view-doctor-profile`,
    editDoctorProfile: `${environment.baseUrl}v1/hospital/edit-doctor-profile`,
    doctorRequestList: `${environment.baseUrl}v1/hospital/doctor-request-list`,
    changedoctorRequestStatus: `${environment.baseUrl}v1/hospital/hospital-accept-doctor`,
    doctorDetail: `${environment.baseUrl}v1/hospital/hospital-find-doctor`,
    addDoctor: `${environment.baseUrl}v1/hospital/hospital-add-doctor`,
    deleteDoctor: `${environment.baseUrl}v1/hospital/hospital-remove-doctor`,
    specialityList: `${environment.baseUrl}v1/hospital/speciality`,
    procedureList: `${environment.baseUrl}v1/hospital/procedure`,
    profileDetail: `${environment.baseUrl}v1/hospital/hospital-profile`,
    updateHospitalProfile: `${environment.baseUrl}v1/hospital/hospital-update-profile`,
    serviceList: `${environment.baseUrl}v1/hospital/hospital-get-service`,
    addService: `${environment.baseUrl}v1/hospital/hospital-add-service`,
    deleteService: `${environment.baseUrl}v1/hospital/hospital-delete-service`,
    faqList: `${environment.baseUrl}v1/hospital/hospital-faq-list`,
    updateFaqs: `${environment.baseUrl}v1/hospital/hospital-update-faq`,
    addFaqs: `${environment.baseUrl}v1/hospital/hospital-add-faq`,
    editFaq: `${environment.baseUrl}v1/hospital/hospital-update-faq`,
    deleteFaq: `${environment.baseUrl}v1/hospital/hospital-delete-faq`,
    videoList: `${environment.baseUrl}v1/hospital/hospital-video-list`,
    addVideo: `${environment.baseUrl}v1/hospital/hospital-add-videos`,
    editVideo: `${environment.baseUrl}v1/hospital/hospital-update-videos`,
    deleteVideo: `${environment.baseUrl}v1/hospital/hospital-delete-videos`,
    hospitalTiming: `${environment.baseUrl}v1/hospital/hospital-get-timing`,
    addHospitalTiming: `${environment.baseUrl}v1/hospital/hospital-add-timing`,
    editHospitalTiming: `${environment.baseUrl}v1/hospital/hospital-update-timing`,
    getAddress: `${environment.baseUrl}v1/hospital/hospital-get-address`,
    editAddress: `${environment.baseUrl}v1/hospital/hospital-update-address`,
    getImagesList: `${environment.baseUrl}v1/hospital/hospital-get-images`,
    deleteImages: `${environment.baseUrl}v1/hospital/hospital-delete-images`,
    addImages: `${environment.baseUrl}v1/hospital/hospital-add-images`,
    socilaList: `${environment.baseUrl}v1/hospital/hospital-social-data`,
    addSocialMedia: `${environment.baseUrl}v1/hospital/hospital-add-social`,
    editSocialMedia: `${environment.baseUrl}v1/hospital/hospital-update-social`,
    deleteSocialMedia: `${environment.baseUrl}v1/hospital/hospital-delete-social`,
    deleteAccount: `${environment.baseUrl}v1/hospital/hospital-delete-account`,
    appointmentCount: `${environment.baseUrl}v1/hospital/dashboard/appointment-count`,
    specialityGraphData: `${environment.baseUrl}v1/hospital/dashboard/appointment-count`,
    hospitalDoctorSpecialityList: `${environment.baseUrl}v1/hospital/speciality/list`,
    hospitaldashboardAppointmentList: `${environment.baseUrl}v1/patient/hospital/appointment-list`,
    appointmentByDoctorGraph: `${environment.baseUrl}v1/hospital/dashboard/doctor-appointment-count`,
  },

  MASTER: {
    specialization: `${environment.baseUrl}v1/master/specialization`,
    hospitalType: `${environment.baseUrl}v1/master/hospital-type`,
    state: `${environment.baseUrl}v1/master/state`,
    speciality: `${environment.baseUrl}v1/master/speciality`,
    procedure: `${environment.baseUrl}v1/master/procedure`,
    social: `${environment.baseUrl}v1/master/social-media`,
  },
  COMMON: {
    fileupload: `${environment.baseUrl}v1/common`,
    emailExist: `${environment.baseUrl}v1/common/email-exist`,
    hospitalList: `${environment.baseUrl}v1/common/hospital-for-address`,
    importDoctor: `${environment.baseUrl}v1/common/import/doctor`,
    UpdatePhoneEmail: `${environment.baseUrl}v1/common/profile-update-send-otp`,
    VerifyPhoneEmail: `${environment.baseUrl}v1/common/profile-update-verify-otp`,
    getNotification: `${environment.baseUrl}v1/common/notification`,
    getIdFromSlug: `${environment.baseUrl}v1/common/slug-for-id`,
    getSitemap: `${environment.baseUrl}v1/sitemap-xml`,
    getAllServices: `${environment.baseUrl}v1/services/get-all-services`,
  },
};

