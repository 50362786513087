import * as i0 from '@angular/core';
import { NgModule } from '@angular/core';
import { faFacebookF, faTwitter, faLinkedinIn, faPinterestP, faRedditAlien, faTumblr, faWhatsapp, faViber, faVk, faFacebookMessenger, faTelegramPlane, faMix, faXing, faLine } from '@fortawesome/free-brands-svg-icons';
import { faSms, faEnvelope, faCheck, faPrint, faExclamation, faLink, faEllipsisH, faMinus } from '@fortawesome/free-solid-svg-icons';
import * as i1 from '@fortawesome/angular-fontawesome';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
const shareIcons = [faFacebookF, faTwitter, faLinkedinIn, faPinterestP, faRedditAlien, faTumblr, faWhatsapp, faViber, faVk, faFacebookMessenger, faTelegramPlane, faMix, faXing, faSms, faEnvelope, faCheck, faPrint, faExclamation, faLink, faEllipsisH, faMinus, faLine];
class ShareIconsModule {
  constructor(iconLibrary) {
    iconLibrary.addIcons(...shareIcons);
  }
}
ShareIconsModule.ɵfac = function ShareIconsModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ShareIconsModule)(i0.ɵɵinject(i1.FaIconLibrary));
};
ShareIconsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ShareIconsModule
});
ShareIconsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [FontAwesomeModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShareIconsModule, [{
    type: NgModule,
    args: [{
      imports: [FontAwesomeModule]
    }]
  }], function () {
    return [{
      type: i1.FaIconLibrary
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ShareIconsModule };
