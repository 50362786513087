<div class="appoinment-dates" >
  <div class="visit-appointment d-flex justify-content-between align-items-center">
    <div class="d-flex gap-1 align-items-center">
      <!-- <img loading="lazy" src="assets/images/pharmacy 1@2x.png" alt="" /> -->
      <p>Select the type of appointment</p>
    </div>
    <div class="cross" (click)="_bottomSheet.dismiss()" *ngIf="type == 'bottomSheet'">
      <svg-icon src="assets/images/svg/cross.svg"></svg-icon>
    </div>
  </div>
  <ul class="tabs_bar_choose" *ngIf="deviceWidth > 767">
    <li (click)="selectTab(1)" [class.active]="selectedTab === 1" class="px-2" style="border-radius: 5px 0px 0px 5px"  >
      <div class="rounded-circle" [ngStyle]="{'border': hideFlagTab === 1 ? '3px solid #acacac' : ''}">
        <div [ngClass]="{ 'active-checkbox': selectedTab === 1 }" class="checkbox-round" autocomplete="on"></div>
      </div>
      <p>In Clinic</p>
    </li>
    <li (click)="selectTab(2)" [class.active]="selectedTab === 2" class="px-2" style="border-radius: 0px 5px 5px 0px" >
      <div class="rounded-circle" [ngStyle]="{'border': hideFlagTab === 2 ? '3px solid #acacac' : ''}">
        <div [ngClass]="{ 'active-checkbox': selectedTab === 2 }" class="checkbox-round"></div>
      </div>
      <p>Video </p>
    </li>
  </ul>
  <div class="doctor-detail" *ngIf="deviceWidth > 767">
    <div class="hospital-name">
      <p>{{ establishmentDetail[0]?.establishmentName }}</p>
      <img src="assets/images/Vector.png" alt="" class="cursor-pointer" (click)="toggle = !toggle" *ngIf="establishmentDetail.length > 1" />
    </div>
    <div class="rating">
      <star-rating [rating]="establishmentDetail[0]?.rating || 0" [step]="0.1" [showHalfStars]="true" [readOnly]="true"></star-rating>
      <span class="star">{{ (establishmentDetail[0]?.rating | number : "1.1-1") || "0.0" }} </span>
      <span class="total"> &nbsp;/5 </span>
      <span class="review" *ngIf="establishmentDetail[0]?.totalReview <= 1">({{ establishmentDetail[0]?.totalReview || "0" }} review)</span>
      <span class="review" *ngIf="establishmentDetail[0]?.totalReview > 1">({{ establishmentDetail[0]?.totalReview || "0" }} reviews)</span>
    </div>
    <div class="other-detail">
      <div class="address" *ngIf="selectedTab === 1">
        <p>
          {{ establishmentDetail[0]?.address?.locality ? establishmentDetail[0]?.address?.locality +" ," : "" || "" }}
          {{ establishmentDetail[0]?.address?.city || "" }}
          {{ establishmentDetail[0]?.address?.pincode || "" }}
        </p>
        <span
          class="cursor-pointer"
          (click)="
          gService.redirectToGoogleMaps(
            establishmentDetail[0]?.location?.coordinates?.[1] || 28.6448,
            establishmentDetail[0]?.location?.coordinates?.[0] || 77.216721
          )
        "
          ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_968_7953)">
              <path d="M17.8413 8.82507L11.1829 2.1584C10.5329 1.5084 9.47461 1.5084 8.82461 2.1584L2.14961 8.82507C1.49961 9.47506 1.49961 10.5334 2.14961 11.1834L8.82461 17.8501C9.47461 18.5001 10.5329 18.5001 11.1829 17.8501L17.8413 11.1834C18.4996 10.5251 18.4996 9.47506 17.8413 8.82507ZM11.2496 12.0834V10.0001H8.33294V12.5001H6.66628V9.16673C6.66628 8.7084 7.04128 8.3334 7.49961 8.3334H11.2496V6.25006L14.1663 9.16673L11.2496 12.0834Z" fill="#45197C" />
            </g>
            <defs>
              <clipPath id="clip0_968_7953">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Get Direction</span
        >
      </div>
      <div class="doctor-fees" *ngIf="selectedTab == 1 && establishmentDetail[0]?.consultationFees!=-1 && establishmentDetail[0]?.consultationFees!=0 && establishmentDetail[0]?.consultationFees!=null && establishmentDetail[0]?.consultationFees!=undefined">
        <span>₹ {{ establishmentDetail[0]?.consultationFees || 0 }}</span>
        <p>Consultation Fees</p>
      </div>
      <div class="doctor-fees" *ngIf="selectedTab == 2 &&  establishmentDetail[0]?.videoConsultationFees!=-1 && establishmentDetail[0]?.videoConsultationFees!=0 && establishmentDetail[0]?.videoConsultationFees!=null && establishmentDetail[0]?.videoConsultationFees!=undefined">
        <span>₹ {{ establishmentDetail[0]?.videoConsultationFees || 0 }}</span>
        <p>Video Consultation Fees</p>
      </div>
    </div>
  </div>

  <!-- view for mobile screen -->

  <div class="mobile-data">
    <ul class="tabs_bar_choose">
      <li (click)="selectTab(1)" [class.active]="selectedTab === 1" class="px-2" style="border-radius: 5px 0px 0px 5px">
        <div class="rounded-circle">
          <div [ngClass]="{ 'active-checkbox': selectedTab === 1 }" class="checkbox-round" autocomplete="on"></div>
        </div>
        <p>In Clinic</p>
      </li>
      <li (click)="selectTab(2)" [class.active]="selectedTab === 2" class="px-2" style="border-radius: 0px 5px 5px 0px">
        <div class="rounded-circle">
          <div [ngClass]="{ 'active-checkbox': selectedTab === 2 }" class="checkbox-round"></div>
        </div>
        <p>Video </p>
      </li>
    </ul>
    <div class="d-flex justify-content-between">
      <div class="d-flex gap-2">
        <div class="profile-image">
          <img [src]="doctorDetail?.profilePic" alt="" height="57" width="57" *ngIf="doctorDetail?.profilePic" class="rounded-circle" />
          <div class="d-flex justify-content-center align-items-center h-100 text-18" *ngIf="!doctorDetail?.profilePic">
            {{ doctorDetail?.name | nameInitial | async | uppercase }}
          </div>
        </div>
        <div>
          <p class="text-16 font-600 m-0">{{ doctorDetail?.name }}</p>
          <span class="text-16 opacity-80 font-400">{{ establishmentDetail[0]?.establishmentName }} </span>
        </div>
      </div>
      <div class="text-16 change-clinic cursor-pointer" (click)="changeClinicMobile()" *ngIf="doctorDetail?.establishmentDetails?.length > 1">
        Change Clinic
        <img loading="lazy" src="assets/images/Vector.png" alt="" />
      </div>
    </div>
  </div>
  <div *ngIf="selectedTab === 1">
    <div class="carousel-container">
      <owl-carousel-o [options]="customOptions" class="caraousel" (translated)="carouselChange($event)" #carousel>
        <ng-container *ngFor="let name of dates; let i = index">
          <ng-template carouselSlide [id]="name" [width]="120">
            <p (click)="getClickedDate(name)" class="cursor-pointer">
              {{ name }}
            </p>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    <!-- <p *ngIf="availableSlots" class="text-center my-3 soltsCount">{{ availableSlots || 0 }} Slots Available</p> -->

    <div class="scheduled-appointment">
      <div class="morningappointment">
        <div class="slots">
          <div class="routine" *ngIf="morningSlots.length">Morning<img loading="lazy" src="assets/images/sunrise 1.png" alt="morning" /></div>
          <p *ngIf="availableSlots" class="soltsCount text-end">{{ availableSlots || 0 }} Slots Available</p>
        </div>
        <p *ngIf="!availableSlots && !(morningSlots.length || noonSlots.length || eveSlots.length)" class="text-center text-danger text-18 mt-3">No Slots Available</p>
        <div class="timing">
          <div class="btn-wrap" *ngFor="let timings of morningSlots">
            <button (click)="selectTime(timings, 'morning')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
      <div class="morningappointment mt-3" *ngIf="noonSlots.length">
        <div class="slots">
          <div class="routine">Afternoon<img src="assets/images/sunset 1.png" style="position: relative; top: -6px" alt="noon" /></div>
        </div>
        <div class="timing">
          <div class="btn-wrap" *ngFor="let timings of noonSlots">
            <button (click)="selectTime(timings, 'afternoon')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
      <div class="morningappointment mt-3" *ngIf="eveSlots.length">
        <div class="slots">
          <div class="routine">Evening<img src="assets/images/night-mode 1.svg" height="24" width="24" alt="night" /></div>
        </div>
        <div class="timing">
          <div *ngFor="let timings of eveSlots" class="btn-wrap">
            <button (click)="selectTime(timings, 'evening')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="selectedTab === 2">
    <div class="carousel-container">
      <owl-carousel-o [options]="customOptions" class="caraousel" (translated)="carouselChange($event)" #carousel>
        <ng-container *ngFor="let name of dates; let i = index">
          <ng-template carouselSlide [id]="name" [width]="120">
            <p (click)="getClickedDate(name)" class="cursor-pointer">
              {{ name }}
            </p>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    <!-- <p *ngIf="availableSlots" class="text-center my-3 soltsCount">
      {{ availableSlots || 0 }} Slots Available
    </p> -->

    <div class="scheduled-appointment">
      <div class="morningappointment">
        <div class="slots">
          <div class="routine" *ngIf="morningSlots.length">Morning<img loading="lazy" src="assets/images/sunrise 1.png" alt="morning" /></div>
          <p *ngIf="availableSlots" class="soltsCount text-end">{{ availableSlots || 0 }} Slots Available</p>
        </div>
        <p *ngIf="!availableSlots && !(morningSlots.length || noonSlots.length || eveSlots.length)" class="text-center text-danger text-18 mt-3">No Slots Available</p>
        <div class="timing">
          <div class="btn-wrap" *ngFor="let timings of morningSlots">
            <button (click)="selectTime(timings, 'morning')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
      <div class="morningappointment mt-3" *ngIf="noonSlots.length">
        <div class="slots">
          <div class="routine">Afternoon<img src="assets/images/sunset 1.png" style="position: relative; top: -6px" alt="noon" /></div>
        </div>
        <div class="timing">
          <div class="btn-wrap" *ngFor="let timings of noonSlots">
            <button (click)="selectTime(timings, 'afternoon')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
      <div class="morningappointment mt-3" *ngIf="eveSlots.length">
        <div class="slots">
          <div class="routine">Evening<img src="assets/images/night-mode 1.svg" height="24" width="24" alt="night" /></div>
        </div>
        <div class="timing">
          <div *ngFor="let timings of eveSlots" class="btn-wrap">
            <button (click)="selectTime(timings, 'evening')" *ngIf="timings?.status == 1" class="button-active">
              {{ timings?.time }}
            </button>
            <button *ngIf="timings?.status !== 1" class="button-inactive">
              {{ timings?.time }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="other-hospital " *ngIf="toggle" nectarClickOutside style="position: absolute; top: 180px; width: 91%;" (clickOutside)="toggle = !toggle">
  <div *ngFor="let item of establishmentDetail; let i = index" class="cursor-pointer border-line" (click)="selectHospital(item)">
    <p *ngIf="i != 0">{{ item?.establishmentName }}</p>
    <div class="rating" *ngIf="i != 0">
      <star-rating [rating]="item?.rating || 0" [step]="0.1" [showHalfStars]="true" [readOnly]="true"></star-rating>
      <span class="star">
        {{ (item?.rating | number : "1.1-1") || "0.0" }}
      </span>
      <span class="total"> /5 </span>
      <span class="review" *ngIf="item?.totalReview <= 1">({{ item?.totalReview || "0" }} review)</span>
      <span class="review" *ngIf="item?.totalReview > 1">({{ item?.totalReview || "0" }} reviews)</span>
    </div>
    <div class="other-detail" *ngIf="i != 0">
      <div class="address">
        <p>
          {{ item?.address?.locality ? item?.address?.locality+ " ,":""   }}
          {{ item?.address?.city || "" }}
          {{ item?.address?.pincode || "" }}
        </p>
        <span
          class="cursor-pointer"
          (click)="
        gService.redirectToGoogleMaps(
          item?.location?.coordinates?.[1] || 28.6448,
          item?.location?.coordinates?.[0] || 77.216721
        )
      "
          >Get Direction</span
        >
      </div>
      <div class="doctor-fees" *ngIf="i != 0 && item?.consultationFees!=-1 && item?.consultationFees!=0 && item?.consultationFees!=null && item?.consultationFees!=undefined" >
        ₹ {{ item?.consultationFees || 0 }} Consultation Fees
      </div>
      <div class="doctor-fees" *ngIf="i != 0 &&  item?.videoConsultationFees!=-1 && item?.videoConsultationFees!=0 && item?.videoConsultationFees!=null && item?.videoConsultationFees!=undefined" >
        ₹ {{ item?.videoConsultationFees || 0 }} Video Consultation Fees
      </div>
    </div>
  </div>
</div>
