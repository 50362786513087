import { Component, Input, OnInit } from "@angular/core";
import { API_ENDPOINTS } from "src/app/config/api.constant";
import { ApiService } from "src/app/services/api.service";
import { CommonService } from "src/app/services/common.service";
import { EventService } from "src/app/services/event.service";

@Component({
  selector: "nectar-services-section",
  templateUrl: "./services-section.component.html",
  styleUrls: ["./services-section.component.scss"],
})
export class ServicesSectionComponent implements OnInit {
  @Input() tab = 1;
  @Input() id: any;
  @Input() type: any = "doctor";

  constructor(
    private apiService: ApiService,
    private eventService: EventService,
    private commonService: CommonService
  ) {}
  viewMore: boolean = false;
  deviceWidth: any;
  itemPerView: number = 10;
  ngOnInit(): void {
    this.deviceWidth = this.commonService.gettingWinowWidth();
    if (this.id) {
      this.getServices();
    }
    this.eventService.getEvent("doctor-route").subscribe((res: any) => {
      if (res) {
        this.id = res;
        this.getServices();
      }
    });
    this.eventService.getEvent("hospital-route").subscribe((res: any) => {
      if (res) {
        this.id = res;
        this.getServices();
      }
    });
  }

  data: any = [];
  serviceArray: any = [];
  getServices() {
    if (this.type == "doctor") {
      this.apiService
        .get(`${API_ENDPOINTS.patient.doctorService}/${this.id}`, {})
        .subscribe((res: any) => {
          this.data = res?.result;
          if (this.deviceWidth < 767) {
            this.serviceArray = this.data.slice(0, this.itemPerView);
          } else {
            this.serviceArray = this.data;
          }
        });
    } else {
      this.apiService
        .get(`${API_ENDPOINTS.patient.hospitalServices}`, {
          establishmentId: this.id,
          type: 2,
        })
        .subscribe((res: any) => {
          this.data = res?.result?.data;
          if (this.deviceWidth < 767) {
            this.serviceArray = this.data.slice(0, this.itemPerView);
          } else {
            this.serviceArray = this.data;
          }
        });
    }
  }

  viewMoreServices() {
    this.viewMore = !this.viewMore;
    if (this.viewMore) {
      this.serviceArray = JSON.parse(JSON.stringify(this.data));
    } else {
      this.serviceArray = this.data.slice(0, this.itemPerView);
    }
  }
}
