<div
  class="main"
  [ngClass]="{ 'border-bottom-style': tab == 0, 'pt-0': tab == 1 }"
>
  <div class="wrapper">
    <div class="heading" *ngIf="tab != 0">
      Doctors in {{ name | titlecase }}
    </div>
    <div class="heading" *ngIf="tab == 0">Book Appointment With Doctors</div>

    <div *ngIf="deviceWidth < 767" class="search mt-3">
      <img
        loading="lazy"
        src="assets/images/svg/search-icon-black.svg"
        alt=""
      />
      <input #search type="text" placeholder="Search" />
    </div>
    <div class="filter">
      <div class="filter-heading">Filter specialities</div>
      <div class="specialities d-flex gap-3">
        <div
          class="box cursor-pointer"
          [class.selected]="selectedSpeciality == 'all'"
          (click)="selectSpecilaity({ name: 'all' })"
        >
          All ({{ totalSpecialaities || 0 }})
        </div>
        <div
          class="box cursor-pointer"
          *ngFor="let i of specialArray"
          [class.selected]="selectedSpeciality == i?.name"
          (click)="selectSpecilaity(i)"
        >
          {{ i?.name | titlecase }} ({{ i?.count || 0 }})
        </div>
      </div>
    </div>

    <div class="list-section" id="list-section" *ngIf="totalCount">
      <div
        class="card"
        *ngFor="
          let doctor of doctorList
            | paginate
              : {
                  itemsPerPage: payload.size,
                  currentPage: payload.page,
                  totalItems: totalCount
                }
        "
      >
        <div class="card-detail d-flex justify-content-between">
          <div class="first-section d-flex gap-2">
            <div class="image-section cursor-pointer">
              <div
                class="profile-icon d-flex align-items-center justify-content-center"
                *ngIf="!doctor?.profilePic"
                (click)="viewDoctor(doctor)"
              >
                {{ doctor?.fullName | nameInitial | async | uppercase }}
              </div>

              <img
                [src]="doctor.profilePic"
                [alt]="doctor?.fullName"
                (click)="viewDoctor(doctor)"
                *ngIf="doctor?.profilePic"
              />
            </div>
            <div class="content">
              <div class="name d-flex align-items-center">
                <span class="cursor-pointer" (click)="viewDoctor(doctor)"
                  >{{ doctor.fullName | titlecase }}
                  <img
                    loading="lazy"
                    src="assets/images/svg/verified_tick.svg"
                    alt=""
                /></span>
              </div>
              <div class="speciality">
                {{ doctor?.specialization | formatarray : "name" }}
              </div>
              <div class="experience">
                {{ doctor.experience || 0 }} Years Experience Overall
              </div>
              <div class="rating" *ngIf="deviceWidth > 767">
                <star-rating
                  [rating]="doctor?.rating || 0"
                  [step]="0.1"
                  [showHalfStars]="true"
                  [readOnly]="true"
                ></star-rating>
                <span class="star">
                  {{ (doctor?.rating | number : "1.0-1") || "0.0" }}</span
                >
                <span class="total">&nbsp;/5 </span>
                <span class="review"
                  >({{ doctor?.reviews || "0" }} reviews)</span
                >
              </div>
              <div class="d-flex gap-3 align-items-center">
                <div class="score d-flex gap-2">
                  <img
                    loading="lazy"
                    src="assets/images/homepage/thumbs-up.svg"
                    alt=""
                  />
                  <span
                    >{{ (doctor?.recommended | number : ".0-2") || 0 }}%</span
                  >
                </div>
                <div class="price-box" *ngIf="deviceWidth > 767">
                  &#8377;{{ doctor?.consultationFees || 0 }}
                  <span class="price"> Consultation Fees </span>
                </div>
                <div
                  *ngIf="deviceWidth < 767"
                  class="text-18 font-600 d-flex align-items-center"
                  style="color: #008000"
                >
                  <img
                    src="assets/images/svg/stars.svg"
                    alt=""
                    height="20"
                    width="20"
                  />

                  {{ (doctor?.rating | number : "1.0-1") || "0.0" }}
                  <span class="font-400 text-black">/5</span>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="deviceWidth < 767" class="mobile-hospital-detail">
            <div class="text-16 font-600">
              {{
                doctor?.establishmentMaster?.address?.locality ??
                  doctor?.establishmentMaster?.address?.landmark
              }}, {{ doctor?.establishmentMaster?.address?.city }}
            </div>
            <div class="text-16 font-400 opacity-80">
              {{ doctor?.establishmentMaster?.hospitalTypeMaster?.name }}
            </div>
            <div class="text-16 font-600">
              ₹{{ doctor?.consultationFees }}
              <span class="font-400 opacity-50">Consultant Fees</span>
            </div>
          </div>

          <div class="second-section">
            <div class="time" *ngFor="let i of doctor?.establishmentTiming">
              <span
                *ngIf="i?.day && (i?.morning || i?.afternoon || i?.evening)"
              >
                <img
                  loading="lazy"
                  src="assets/images/svg/Access time.svg"
                  alt=""
                />
                {{ i?.day | titlecase }}</span
              >

              <p *ngIf="i?.morning">{{ i?.morning }}</p>
              <p *ngIf="i?.afternoon">{{ i?.afternoon }}</p>
              <p *ngIf="i?.evening">{{ i?.evening }}</p>
            </div>
          </div>
        </div>

        <div class="booking d-flex justify-content-between">
          <div
            class="wait-time d-flex gap-2 align-items-center align-self-end"
            [style.visibility]="doctor?.waitTime ? 'visible' : 'hidden'"
          >
            <img loading="lazy" src="assets/images/svg/clock.svg" alt="" />
            <span>{{ doctor?.waitTime }} wait time</span>
          </div>
          <button
            [style.opacity]="doctor?.isActive ? '1' : '0.5'"
            (click)="bookAppointment(doctor)"
          >
            Book Appointment
          </button>
        </div>
      </div>
      <div
        class="seeMore mt-2"
        *ngIf="tab == 0 && totalCount > payload.size"
        (click)="valueChange.emit('true')"
      >
        See more
      </div>
      <div class="pagination-section" *ngIf="totalCount > 10 && tab != 0">
        <pagination-controls
          class="text-center"
          [previousLabel]="' '"
          [nextLabel]="' '"
          (pageChange)="changingPage($event)"
        ></pagination-controls>
      </div>
    </div>
  </div>
</div>
