import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "nectar-seo-content",
  templateUrl: "./seo-content.component.html",
  styleUrls: ["./seo-content.component.scss"],
})
export class SeoContentComponent implements OnInit {
  @Input() allowDescription: boolean = true;
  @Input() data: any = "";
  @Input() city: string = "";
  @Input() search: string = "";

  deviceWidth: any;
  constructor(
    private sanitizer: DomSanitizer,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.deviceWidth = this.commonService.gettingWinowWidth();
  }

  sanitizeHtml(str: string) {
    str = str.replace(/@variable/g, this.city); //replacing variable with city name
    str = str.replace(/&amp;nbsp;/g, "&nbsp;");
    return this.sanitizer.bypassSecurityTrustHtml(str);
  }
}
